import AuthCall from "./auth";
import { ApiResponse, EndpointRequest } from "../types/base";
import { CrfDataApi } from "../../types/crf";

const CrfAPI = {
  getCrf: async ({ id, signal }: { id: string } & EndpointRequest) => await AuthCall.get({
    endpoint: `/secure/medical/crf/${id}`,
    signal,
  }) as ApiResponse<CrfDataApi>,
  uploadCrf: async ({ id, data, signal }: { id: string, data: CrfDataApi } & EndpointRequest) => await AuthCall.put({
    endpoint: `/secure/medical/crf/${id}`,
    data,
    signal,
  }),
}

export default CrfAPI;
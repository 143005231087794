import { alpha, Box, Tooltip, useTheme } from "@mui/material";
import { ImageMetadataUI, isImageValid } from "../../types/image";
import { DAY_SIZE } from "./utils";
import DayTooltip from "./DayTooltip";

const CalendarDay = ({
  day,
  active = true,
  today = false,
  startDay = false,
  finishDay = false,
  dueDay = false,
  imageData,
}: {
  /** 1-indexed, i.e. same as a standard calendar */
  day: number,
  active: boolean,
  today?: boolean,
  startDay?: boolean,
  finishDay?: boolean,
  dueDay?: boolean,
  imageData?: ImageMetadataUI,
}) => {
  const theme = useTheme();
  const state = !imageData ? 'default' : (isImageValid(imageData) ? 'success' : 'error');

  let color:          string | undefined = undefined;
  let bgcolor:        string | undefined = undefined;
  let outlineStyle:   string | undefined = undefined;
  let outlineColor:   string | undefined = undefined;
  let outlineWidth:   string | undefined = undefined;
  let outlineOffset:  string | undefined = undefined;

  switch (state) {
    case 'success':
      color = theme.palette.secondary.contrastText;
      bgcolor = active ? theme.palette.secondary.main : alpha(theme.palette.secondary.main, 0.5);
      break;
    case 'error':
      color = theme.palette.error.contrastText;
      bgcolor = active ? theme.palette.error.main : alpha(theme.palette.secondary.main, 0.5);
      break;
    case 'default':
    default:
      color = active ? theme.palette.text.primary : theme.palette.text.disabled;
      bgcolor = active ? theme.palette.action.hover : undefined;
      break;
  }

  if (today || startDay || finishDay || dueDay) {
    outlineColor = (state === 'default') ? theme.palette.secondary.main : theme.palette.text.secondary;
    outlineStyle = today ? 'dashed' : 'solid';
    outlineWidth = '2px';
    outlineOffset = '-2px';
  }

  const element = (
    <Box sx={{
      width: DAY_SIZE, height: DAY_SIZE,
      borderRadius: DAY_SIZE / 2,
      display: 'flex',
      alignItems: 'center', justifyContent: 'center',
      color,
      bgcolor,
      outlineStyle,
      outlineColor,
      outlineWidth,
      outlineOffset,
    }}>{day}</Box>
  )

  return (today || startDay || finishDay || dueDay || imageData) ? (
    <Tooltip
      title={<DayTooltip today={today} startDay={startDay} finishDay={finishDay} dueDay={dueDay} imageData={imageData}/>}
      placement='left'
      disableInteractive={!imageData}
      leaveDelay={50}
    >
      {element}
    </Tooltip>
  ) : (
    element
  );
}

export default CalendarDay;
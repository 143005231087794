import { Box, Divider, SxProps, Typography } from "@mui/material";
import Section from "../../components/Section";
import QuestionAnswer from "./QuestionAnswer";

const FAQSection = ({
  sx,
}: {
  sx?: SxProps,
}) => {
  return (
    <Section sx={{ alignSelf: 'stretch', width: '800px', p: 0, ...sx }}>
      <Typography variant='h5' sx={{ mx: 3, my: 2 }}>Frequently Asked Questions</Typography>
      <Divider/>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, px: 3, py: 2, overflow: 'auto' }}>
        {FAQ_DATA.map((faq, index) => (
          <QuestionAnswer key={index} question={faq.question} answer={faq.answer}/>
        ))}
      </Box>
    </Section>
  );
}

export default FAQSection;

const FAQ_DATA: Array<{ question: string, answer: string | string[] }> = [
  {
    question: 'What is the Salurate Study?',
    answer: 'The study objective is to clinically and logistically validate a novel remote self-monitoring screening system - Salurate - that uses salivary uric acid measurement to identify women at risk of developing hypertensive disorders of pregnancy (HDP) several weeks earlier than current methods.',
  },
  // {
  //   question: 'How do I set up my Clinician Portal login?',
  //   answer: [
  //     'To set up an account, you will need your NHS email address. Go to the \'Sign Up\' tab of the login screen, then enter your email, name, secure password, and the sign-up code. The research fellow will provide this code. After signing up, you can switch to the \'Sign In\' tab and log in.',
  //     'Once logged in, go to the \'Account\' screen and provide additional information such as the name of your organisation and the address for where to deliver new Salurate kits.',
  //   ],
  // },
  {
    question: 'What should I do if an unauthorised person accessed my Clinician Portal account details?',
    answer: 'If you believe someone else has access to your account, change your password. In the Clinician Portal, go to the \'Account\' screen, then press the \'Change Password\' button and enter your current and new passwords.',
  },
  {
    question: 'How do I add a new participant to the Clinician Portal?',
    answer: 'Go to either the \'Users\' screen and select \'Add User\', or the \'Stock\' screen and select \'Issue Kit to User\'. You can then type or select the Study ID associated with the kit you will handle for the participant. Add their Due Date reading from the ultrasound, then click \'Confirm\'.',
  },
  {
    question: 'Where can I check if participants are submitting their samples?',
    answer: 'Go to the \'Users\' screen, then select the participant from the list on the left side of the screen. Once selected, the participants\' submission history will appear on the right side of the screen.',
  },
  {
    question: 'What should I do if participants lose their Salurate kit components (test cartridge and/or swabs)?',
    answer: 'Issue a new kit to the participant. Go to the \'Users\' screen, then select the participant from the list on the left side of the screen. On the right side of the screen under \'User Details\', press the \'+\' button next to their Study ID and enter the ID for the replacement kit.',
  },
  {
    question: 'What happens if I send a new kit to a participant and forget to add it to the portal?',
    answer: 'If you are aware of their previous Study ID and the new Study ID, please follow the process you would do to issue a replacement kit.',
  },
  {
    question: 'Where can I find more information if I have a question not mentioned in the FAQs or other clinician supporting documents?',
    answer: 'Please contact the trial research fellow (Dr Basia Chmielewska - basia.chmielewska@nhs.net). If they can\'t answer your question, they can contact the sponsor.',
  },
  {
    question: 'What should I do if the participants have not sent any images for over one week?',
    answer: 'Please get in touch with the participants and remind them to do the test.',
  },
  {
    question: 'How long should I wait for the participants to log in to the app?',
    answer: 'If a participant did not log into the app after a week of joining the study, please contact them.',
  },
  {
    question: 'What do I do with expired kits?',
    answer: 'If you have expired kits, please quarantine them so they can be returned to the manufacturer.',
  },
  {
    question: 'Can I change my details in the Clinician Portal?',
    answer: 'You can change your name and password. To do so, go to the \'Account\' screen then press \'Edit\', make any changes you wish to make, then press \'Save\'. You cannot change your login email.',
  },
  {
    question: 'Will I have access to the test results?',
    answer: 'We cannot tell you anything about the participants\' test results. Once the study is finished, you can request to see data through the Sponsor. Salurate study is a non-interventional study, as it will not interfere with the participants\' care path, and the sponsor will compare the study results with current methods.',
  },
  {
    question: 'What should I do if I run out of kits?',
    answer: 'Please make sure that you follow the Laboratory Manual (doc: HR-P-64) provided by the sponsor. However, if you run out of kits, use the Clinician Portal to request additional stock. Go to the \'Stock\' screen, then press \'Request Kits\' to request new kits.',
  },
  {
    question: 'What happens if a participant was diagnosed with a hypertensive disorder of pregnancy during the study by one of the current methods?',
    answer: 'Encourage them to continue in the clinical trial, update the CRF in the portal, and continue with the standard treatment as Salurate does not change the care pathway.',
  },
  {
    question: 'What happens if a participant delivers their baby earlier than expected in the due date predicted by the ultrasound scan?',
    answer: 'If the participant delivers their baby before the estimated due date, please update the relevant section on their CRF regarding the delivery and baby. Inform the participant that they no longer need to take samples.',
  },
  {
    question: 'What happens if a participant has a miscarriage or the baby is a stillbirth?',
    answer: 'If the participant loses their baby during the trial, please update the relevant section on their CRF regarding the delivery and baby. Inform the participant that they no longer need to take samples.',
  },
]
import { DateTime } from "luxon";
import { ImageMetadata, ImageMetadataUI } from "../../types/image";
import { GroupedImagesCalendar } from "./types";

export const DAY_SIZE = 34;
export const DAY_SPACING = 6;
export const TOTAL_WIDTH = (DAY_SIZE + DAY_SPACING) * 7 - DAY_SPACING;

export const DAYS = [ 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su' ];

export const groupImageMetadata = (images?: ImageMetadata[]) => {
  const formattedImages: GroupedImagesCalendar = {};
  let image: ImageMetadataUI, year: number, month: number, day: number;

  if (!images || images.length === 0) {
    return formattedImages;
  }

  for (let i = 0; i < images.length; i++) {
    image = convertImageMetadataToUI(images[i]);

    year  = image.imageTimestamp.year;
    month = image.imageTimestamp.month;
    day   = image.imageTimestamp.day;

    if (!formattedImages[year]) {
      formattedImages[year] = {};
    }

    if (!formattedImages[year][month]) {
      formattedImages[year][month] = {};
    }

    // Use image with latest capture timestamp
    if (!formattedImages[year][month][day] || (image.imageTimestamp > formattedImages[year][month][day].imageTimestamp)) {
      formattedImages[year][month][day] = image;
    }
  }

  return formattedImages;
}

export const convertImageMetadataToUI = (image: ImageMetadata) => ({
  ...image,
  uploadTimestamp: DateTime.fromISO(image.uploadTimestamp, {zone: 'utc'}),
  imageTimestamp: DateTime.fromISO(image.imageTimestamp || image.uploadTimestamp, {zone: 'utc'}),
  processingTimestamp: image.processingTimestamp && DateTime.fromISO(image.processingTimestamp, {zone: 'utc'}),
} as ImageMetadataUI)

export const convertImageMetadataFromUI = (image: ImageMetadataUI) => ({
  ...image,
  uploadTimestamp: image.uploadTimestamp.toISO(),
  imageTimestamp: image.imageTimestamp.toISO(),
  processingTimestamp: image.processingTimestamp?.toISO(),
} as ImageMetadata)

export const getDayOfMonth = (month: number, year: number, targetDate: DateTime<boolean>) => {
  if (targetDate.year === year && targetDate.month === month) {
    // Current month -> find exact day
    return targetDate.day;
  }
  else if ((targetDate.year < year) || (targetDate.year === year && targetDate.month < month)) {
    // Previous month -> -1
    return -1;
  }
  else {
    // Future month -> greater than MAX_DAYS
    return 50;
  }
}
import React from "react";
import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "../../redux/hooks";
import { selectEmail, selectFirstName, selectLastName, updateAccountData } from "../../redux/slices/account";
import { ChangeAccountData } from "../../types/account";
import { removeUndefined } from "../../utils/global";
import ScreenBox from "../../components/ScreenBox";
import Section from "../../components/Section";
import DialogChangePassword from "./DialogChangePassword";

const AcccountScreen = () => {
  const dispatch = useDispatch();
  const email = useSelector(selectEmail);
  const firstName = useSelector(selectFirstName);
  const lastName = useSelector(selectLastName);

  const [isEditing, setIsEditing] = React.useState(false);
  const [accountData, setAccountData] = React.useState<ChangeAccountData>({})
  const [isChangePasswordOpen, setChangePasswordOpen] = React.useState(false);

  React.useEffect(() => {
    setAccountData({ firstName, lastName });
  }, [firstName, lastName]);

  const handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
    setAccountData({
      ...accountData,
      [e.target.name]: e.target.value,
    });
  }

  const handleClickCancel = () => {
    setAccountData({ firstName, lastName });
    setIsEditing(false)
  }

  const handleClickSave = () => {
    const changes = removeUndefined({
      firstName: firstName !== accountData.firstName ? accountData.firstName : undefined,
      lastName: lastName !== accountData.lastName ? accountData.lastName : undefined,
    })

    if (!!changes && Object.keys(changes).length > 0) {
      dispatch(updateAccountData(changes))
      .then(() => { setIsEditing(false) });
    }
    else {
      setIsEditing(false)
    }
  }

  const handleClickChangePassword = () => setChangePasswordOpen(true)

  return (
    <ScreenBox>
      <Section sx={{ alignSelf: 'stretch', width: '560px', p: 0 }}>
        <Box sx={{ px: 3, py: 2, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Typography variant='h5'>Account Details</Typography>

          {isEditing ? (
            <>
              <Button variant='text' onClick={handleClickCancel} sx={theme => ({ ml: 'auto', mr: 1, color: theme.palette.text.secondary })}>Cancel</Button>
              <Button variant='text' onClick={handleClickSave}>Save</Button>
            </>
          ) : (
            <Button variant='text' onClick={() => setIsEditing(true)} sx={{ ml: 'auto' }}>Edit</Button>
          )}
        </Box>
        <Divider/>

        <Box sx={{ px: 3, py: 2, display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
          <Typography variant='h6' color={theme => theme.palette.text.secondary}>Name</Typography>
          <TextField
            disabled={!isEditing}
            label='First Name'
            name='firstName'
            value={accountData.firstName || ''}
            onChange={handleChange}
            sx={{ mt: 1, mb: 2 }}
          />
          <TextField
            disabled={!isEditing}
            label='Last Name'
            name='lastName'
            value={accountData.lastName || ''}
            onChange={handleChange}
          />

          <Typography variant='h6' color={theme => theme.palette.text.secondary} sx={{ mt: 4 }}>Email</Typography>
          <Typography>{email}</Typography>

          {/* {role === AccountRole.MEDIC && (
            <>
              <Typography variant='h6' color={theme => theme.palette.text.secondary} sx={{ mt: 4 }}>Site (ID)</Typography>
              <Typography variant='h5'>{(siteId && sites[siteId]) || BLANK }</Typography>
              <Typography variant='h5'>{siteId}</Typography>
            </>
          )} */}
          
          <Button variant='text' onClick={handleClickChangePassword} sx={{ mt: 1, alignSelf: 'end' }}>Change Password</Button>
        </Box>

        <DialogChangePassword open={isChangePasswordOpen} onClose={() => setChangePasswordOpen(false)}/>
      </Section>
    </ScreenBox>
  );
}

export default AcccountScreen;
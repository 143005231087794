import { Box, SxProps, Theme, Typography } from '@mui/material';
import { BLANK } from '../utils/global';
import React from 'react';
import IconButton from './IconButton';

const Parameter = ({
  title,
  primaryValue,
  secondaryValue,
  titleWidth = 150,
  valueWidth = 200,
  buttonLabel,
  buttonIcon,
  buttonDisabled,
  onClick,
  sx,
}: {
  title: string,
  primaryValue?: string,
  secondaryValue?: string | string[],
  titleWidth?: number,
  valueWidth?: number,
  buttonLabel?: string,
  buttonIcon?: React.ReactNode,
  buttonDisabled?: boolean,
  onClick?: () => void,
  sx?: SxProps<Theme>,
}) => {
  const getSecondaryLine = (text: string, index?: number) => (
    <Typography key={index} variant='body2' color={theme => theme.palette.text.secondary}>{text}</Typography>
  )

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'start', py: '2px', ...sx }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: `${titleWidth}px`, mr: 2 }}>
        <Typography sx={theme => ({ color: theme.palette.text.secondary })}>{title}</Typography>

        {buttonIcon && onClick && (
          <IconButton disabled={buttonDisabled} title={buttonLabel} sx={theme => ({ color: theme.palette.primary.main, ml: 'auto', my: '-3px', p: '3px' })} onClick={onClick}>{buttonIcon}</IconButton>
        )}

        {/* {buttonLabel && onClick && (
          <Button variant='text' onClick={onClick} sx={{
            ml: -1.5,
            textTransform: 'none',
            fontSize: '0.875rem',
          }}>{buttonLabel}</Button>
        )} */}
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', width: `${valueWidth}px` }}>
        <Typography>{primaryValue !== undefined ? primaryValue : BLANK}</Typography>

        {secondaryValue && (Array.isArray(secondaryValue) ? secondaryValue.map((line, idx) => getSecondaryLine(line, idx)) : getSecondaryLine(secondaryValue))}
      </Box>
    </Box>
  );
}

export default Parameter;
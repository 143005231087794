import { CSSObject, IconButton as MuiIconButton, styled } from "@mui/material";

const IconButton = styled(MuiIconButton)<{
  variant?: 'contained' | 'icon',
}>(({ theme, variant, color }) => {
  const overrides: CSSObject = {
    padding: '6px',
  };

  const colorVariant = (color === undefined || color === 'inherit' || color === 'default') ? 'primary' : color;

  if (variant === 'contained') {
    overrides.backgroundColor = theme.palette[colorVariant].main;
    overrides.color = theme.palette[colorVariant].contrastText;
    overrides.boxShadow = theme.shadows[2];
    overrides[':hover'] = {
      backgroundColor: theme.palette[colorVariant].dark,
    }
  }

  return { ...overrides };
})

export default IconButton;
import { AccountRole } from "../types/account";
import { Patient } from "../types/patient";
import { Testkit, TestkitAPIStatus } from "../types/testkit";
import { isoDateTimeToDateTime } from "./dateTime";
import { BLANK } from "./global";

export const DEFAULT_API_COUNT = 100;

export const getStatusTimestamp = ({ testkit, status }: { testkit?: Testkit, status: TestkitAPIStatus }) => {
  return testkit ? testkit.transitions.find((transition) => transition.status === status)?.timestamp : undefined;
}

export const getStatusTimestampDateTime = ({ testkit, status }: { testkit?: Testkit, status: TestkitAPIStatus }) => {
  const timestamp = getStatusTimestamp({ testkit, status });

  return timestamp ? isoDateTimeToDateTime(timestamp) : undefined;
}

export const removePatientIdPrefix = (id: string) => {
  return id.replace('P-', '');
}

export const removePatientIdPrefixPatients = (patients: Patient[]) => {
  patients.forEach(patient => { patient.id = removePatientIdPrefix(patient.id) });
}

export const removePatientIdPrefixTestkits = (testkits: Testkit[]) => {
  testkits.forEach(kit => { kit.patient = kit.patient && removePatientIdPrefix(kit.patient) });
}

export const removeDummyKit = (testkits: Testkit[], role?: AccountRole) => {
  if (role !== AccountRole.ADMIN) {
    const idx = testkits.findIndex(kit => kit.id === process.env.REACT_APP_DUMMY_KIT);

    if (idx >= 0) {
      testkits.splice(idx, 1);
    }
  }
}

export const removeDummyPatient = (patients: Patient[], role?: AccountRole) => {
  if (role !== AccountRole.ADMIN) {
    const idx = patients.findIndex(patient => patient.id === process.env.REACT_APP_DUMMY_KIT);

    if (idx >= 0) {
      patients.splice(idx, 1);
    }
  }
}

export const getTestkitLocation = ({ kit, role, sites }: { kit: Testkit, role?: AccountRole, sites: {[key: string]: string} }) => {
  switch (kit.status) {
    // case TestkitAPIStatus.SUB_ASSEMBLY:
    //   return kit.manufacturer;
    case TestkitAPIStatus.STOCK:
      return 'MIAT';
    case TestkitAPIStatus.ALLOCATED:
      if (!kit.dispatched) {
        return role === AccountRole.ADMIN ? 'MIAT' : 'Sponsor (MIAT)'
      }
      else {
        return `Sent to ${(kit.site && sites[kit.site]) || 'trial site'}`
      }
    case TestkitAPIStatus.ISSUED:
      return 'Issued to user';
    default:
      return BLANK;
  }
}
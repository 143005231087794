import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import type { RootState } from "../store";
import { ApiErrorResponse, EndpointRequest, FilterPaginationDateTime } from "../../api/types/base";
import { showErrorMessage } from "../utils";
import { convertSorterToAPI, FinishTrialReason, Patient, PatientFilter, PatientUISorter } from "../../types/patient";
import PatientAPI from "../../api/endpoints/patient";
import { ImageMetadata, ImageStatus } from "../../types/image";
import ImageAPI from "../../api/endpoints/image";
import { DateTime } from "luxon";
import { calcPregnancyWeek, getISO } from "../../utils/dateTime";
import { Toast } from "../../components/Toast";
import { AccountData } from "../../types/account";
import { COOKIE_ROLE, getAccountFullName } from "../../utils/global";
import { removeDummyPatient, removePatientIdPrefix } from "../../utils/testkit";

interface PatientState {
  patientFilter?: PatientFilter,
  patientSorter: PatientUISorter,
  isLoadingPatients: boolean,
  patients: Patient[],
  selectedPatient?: Patient,

  isLoadingPatientImages: boolean,
  patientImages?: ImageMetadata[],
  displayedImage?: ImageMetadata,
}

const cookies = new Cookies(undefined, { path: '/', sameSite: 'lax' });

const initialState: PatientState = {
  patientFilter: undefined,
  patientSorter: { column: 'id', dir: 'asc' },
  isLoadingPatients: false,
  patients: [],
  selectedPatient: undefined,

  isLoadingPatientImages: false,
  patientImages: undefined,
  displayedImage: undefined,
}

// NOTE: 'state' is only mutable inside 'createSlice'
export const patientSlice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    setSelectedPatient: (state, action: PayloadAction<Patient | undefined>) => {
      state.selectedPatient = action.payload;
    },
    clearPatientImages: (state) => {
      state.patientImages = undefined;
    },
    setDisplayedImage: (state, action: PayloadAction<ImageMetadata | undefined>) => {
      state.displayedImage = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Thunk: getPatients
    builder.addCase(getPatients.pending, (state, action) => {
      state.isLoadingPatients = true;

      // If filter was specified -> update stored filter
      if (!!action.meta.arg.filter) {
        state.patientFilter = action.meta.arg.filter;
      }
      
      // If sorter was specified -> update stored sorter
      if (!!action.meta.arg.sorter) {
        state.patientSorter = action.meta.arg.sorter;
      }
    });
    builder.addCase(getPatients.fulfilled, (state, action) => {
      state.isLoadingPatients = false;
      state.selectedPatient = undefined;
      
      let patients = action.payload?.data || [];
      patients = patients.filter(patient => !!patient);   // TEMP: Remove null patients (should never happen)

      patients.forEach(patient => {
        patient.id = removePatientIdPrefix(patient.id);

        if (patient.week <= 0) {
          patient.week = calcPregnancyWeek(DateTime.fromISO(patient.dueDate));
        }
      });
      removeDummyPatient(patients, cookies.get(COOKIE_ROLE) || undefined);
      state.patients = patients;
    });
    builder.addCase(getPatients.rejected, (state, action) => {
      state.isLoadingPatients = false;
      
      showErrorMessage(action.payload as ApiErrorResponse, { title: 'Failed to retrieve user data' });
    });

    // Thunk: getPatientByID
    builder.addCase(getPatientByID.fulfilled, (state, action) => {
      const patient = action.payload.data;
      patient.id = removePatientIdPrefix(patient.id);
      state.selectedPatient = patient;
    });
    builder.addCase(getPatientByID.rejected, (state, action) => {
      showErrorMessage(action.payload as ApiErrorResponse, { title: `Failed to retrieve data for user ${action.meta.arg.id}` });
    });
    
    // Thunk: getAllImages
    builder.addCase(getAllImages.pending, (state, action) => {state.isLoadingPatientImages = true; });
    builder.addCase(getAllImages.fulfilled, (state, action) => {
      state.isLoadingPatientImages = false;
      state.patientImages = action.payload?.data || [];
    });
    builder.addCase(getAllImages.rejected, (state, action) => {
      state.isLoadingPatientImages = false;
      
      showErrorMessage(action.payload as ApiErrorResponse, { title: 'Failed to retrieve image data' });
    });
    
    // Thunk: addUser
    builder.addCase(addUser.fulfilled, (state, action) => {
      Toast.success({ title: `Assigned user to kit ${action.meta.arg.id}` });
    });
    builder.addCase(addUser.rejected, (state, action) => {
      showErrorMessage(action.payload as ApiErrorResponse, { title: `Failed to assign user to kit ${action.meta.arg.id}` });
    });
    
    // Thunk: addSecondaryID
    builder.addCase(addSecondaryID.fulfilled, (state, action) => {
      Toast.success({ title: `Issued kit ${action.meta.arg.secondaryId} to user ${action.meta.arg.primaryId}` });
    });
    builder.addCase(addSecondaryID.rejected, (state, action) => {
      showErrorMessage(action.payload as ApiErrorResponse, { title: `Failed to issue kit to user ${action.meta.arg.primaryId}` });
    });
    
    // Thunk: transferToMidwife
    builder.addCase(transferToMidwife.fulfilled, (state, action) => {
      const idx = state.patients.findIndex(patient => patient.id === action.meta.arg.patient.id);
      state.patients[idx].midwife = action.payload.data.midwife;
      state.patients[idx].site = action.payload.data.site;
      
      // Selected patient should always be the patient being transferred, but check anyway
      if (state.selectedPatient?.id === action.meta.arg.patient.id) {
        state.selectedPatient = state.patients[idx];
      }

      Toast.success({ title: `Transferred user ${action.meta.arg.patient.id} to ${getAccountFullName(action.meta.arg.midwife)}` });
    });
    builder.addCase(transferToMidwife.rejected, (state, action) => {
      showErrorMessage(action.payload as ApiErrorResponse, { title: `Failed to transfer user to ${getAccountFullName(action.meta.arg.midwife)}` });
    });
    
    // Thunk: transferAllToMidwife
    builder.addCase(transferAllToMidwife.fulfilled, (state, action) => {
      Toast.success({ title: `Transferred all users from ${getAccountFullName(action.meta.arg.oldMidwife)} to ${getAccountFullName(action.meta.arg.newMidwife)}` });
    });
    builder.addCase(transferAllToMidwife.rejected, (state, action) => {
      showErrorMessage(action.payload as ApiErrorResponse, { title: `Failed to transfer users from users from ${getAccountFullName(action.meta.arg.oldMidwife)} to ${getAccountFullName(action.meta.arg.newMidwife)}` });
    });
    
    // Thunk: completePregnancy
    builder.addCase(completePregnancy.fulfilled, (state, action) => {
      if (state.patientFilter?.includeCompleted) {
        // Include finished -> update user info with response data
        const idx = state.patients.findIndex(patient => patient.id === action.meta.arg.id);

        if (idx >= 0) {
          const patient = {
            ...action.payload.data,
            id: action.meta.arg.id,
          };
          
          state.patients = [...state.patients];
          state.patients[idx] = patient;
          state.selectedPatient = patient;
        }
      }
      else {
        // Exclude finished -> hide user from list
        state.patients = state.patients.filter(patient => patient.id !== action.meta.arg.id);
        state.selectedPatient = undefined;
      }

      Toast.success({ title: `Finished trial for user ${action.meta.arg.id}` });
    });
    builder.addCase(completePregnancy.rejected, (state, action) => {
      showErrorMessage(action.payload as ApiErrorResponse, { title: `Failed to finish trial for user ${action.meta.arg.id}` });
    });
  },
});

export default patientSlice.reducer;
export const {
  setSelectedPatient,
  clearPatientImages,
  setDisplayedImage,
} = patientSlice.actions;

export const getPatients = createAsyncThunk('patient/getPatients',
  async ({ filter, sorter, signal }: { filter?: PatientFilter, sorter?: PatientUISorter } & EndpointRequest, { getState, rejectWithValue }) => {
    const state = getState() as RootState;

    const apiFilter: PatientFilter = { ...(filter || selectPatientFilter(state)) };
    const apiSorter = sorter || selectPatientSorter(state);
    
    return PatientAPI.getAllPatients({
      signal,
      limit: 200,
      ...apiFilter,
      ...convertSorterToAPI(apiSorter),
    })                                            // API success -> thunk payload is ApiResponse
    .catch(error => rejectWithValue(error));      // API failure -> thunk payload is ApiErrorResponse
  },
);

export const getPatientByID = createAsyncThunk('patient/getPatientByID',
  async ({ id, signal }: { id: string } & EndpointRequest, { rejectWithValue }) => {
    return PatientAPI.getPatientByID({ id, signal })
    .catch(error => rejectWithValue(error));
  },
);

export const getAllImages = createAsyncThunk('patient/getAllImages',
  async ({ id, status, startTime, endTime, limit, offset, signal }: { id?: string, status?: ImageStatus } & FilterPaginationDateTime & EndpointRequest, { rejectWithValue }) => {
    if (id) {
      return ImageAPI.getImagesByPatient({ id, startTime, endTime, limit, offset, signal })
      .catch(error => rejectWithValue(error))
    }
    else if (status) {
      return ImageAPI.getImagesByStatus({ status, startTime, endTime, limit, offset, signal })
      .catch(error => rejectWithValue(error))
    }
    else {
      return ImageAPI.getAllImages({ startTime, endTime, limit, offset, signal })
      .catch(error => rejectWithValue(error))
    }
  },
);

export const addUser = createAsyncThunk('patient/addUser',
  async ({ id, dueDate, signal }: { id: string, dueDate: DateTime } & EndpointRequest, { rejectWithValue }) => (
    PatientAPI.issueKitToPatient({ id, dueDate: dueDate.toFormat('yyyy-MM-dd') || '', signal })
    .catch(error => rejectWithValue(error))
  ),
);

export const addSecondaryID = createAsyncThunk('patient/addSecondaryID',
  async ({ primaryId, secondaryId, signal }: { primaryId: string, secondaryId: string } & EndpointRequest, { rejectWithValue }) => (
    PatientAPI.issueKitToExistingPatient({ primaryId, secondaryId, signal })
    .catch(error => rejectWithValue(error))
  ),
);

export const transferToMidwife = createAsyncThunk('patient/transferToMidwife',
  async ({ patient, midwife, siteId, signal }: { patient: Patient, midwife: AccountData, siteId?: string } & EndpointRequest, { rejectWithValue }) => (
    PatientAPI.transferPatientToMidwife({ id: patient.id, oldMidwife: patient.midwife, newMidwife: midwife.username, newSiteId: siteId, signal })
    .catch(error => rejectWithValue(error))
  ),
);

export const transferAllToMidwife = createAsyncThunk('patient/transferAllToMidwife',
  async ({ oldMidwife, newMidwife, siteId, signal }: { oldMidwife: AccountData, newMidwife: AccountData, siteId?: string } & EndpointRequest, { rejectWithValue }) => (
    PatientAPI.transferAllPatientsToMidwife({ oldMidwife: oldMidwife.username, newMidwife: newMidwife.username, newSiteId: siteId, signal })
    .catch(error => rejectWithValue(error))
  ),
);

export const completePregnancy = createAsyncThunk('patient/completePregnancy',
  async ({ id, reason, signal }: { id: string, reason: FinishTrialReason } & EndpointRequest, { rejectWithValue }) => (
    PatientAPI.completePregnancy({ id, reason, signal })
    .catch(error => rejectWithValue(error))
  ),
);

export const selectPatientFilter = (state: RootState) => state.patient.patientFilter;
export const selectPatientSorter = (state: RootState) => state.patient.patientSorter;
export const selectIsLoadingPatients = (state: RootState) => state.patient.isLoadingPatients;
export const selectPatients = (state: RootState) => state.patient.patients;
export const selectSelectedPatient = (state: RootState) => state.patient.selectedPatient;

export const selectIsLoadingPatientImages = (state: RootState) => state.patient.isLoadingPatientImages;
export const selectPatientImages = (state: RootState) => state.patient.patientImages;
export const selectDisplayedImage = (state: RootState) => state.patient.displayedImage;

export enum ResponseCode {
  Success,
  Unknown,

  ImageFileMissing,
  IncorrectInputParams,
  InvalidTestkitID,
  EmptyTestkitData,
  InvalidTestkitCreate,
  InvalidTestkitUpdate,
  InvalidTestkitStock,
  InvalidTestkitIssue,
  MidwifeDoesNotExist,
  InvalidKitStatus,

  WrongUsernamePassword,
  WrongEmailFormat,
  JwtSignatureException,
  JwtMalformedTokenException,
  JwtTokenExpiredException,
  JwtTokenUnsupportedException,
  JwtTokenMissingException,
  UsernameEmailUnverified,
  IncorrectActivationCode,
  UsernameBarred,
  OperationNotPermitted,
  WrongApiKeySecret,
  PasswordPolicyException,

  EntityNotFound,
  AccountDataError,
  
  UserAlreadyExists,
  UserNotMedic,

  InsufficientTestkitsInStock,
  TestkitNotAllocated,
  TestkitWrongMidwife,
  TestkitIssuingError,

  ImageUploadError,
  CsvDownloadError,
}

export type ResponseCodeData = {
  code: string,
  id: ResponseCode,
  description: string,
}

const RESPONSE_CODE_DATA: ResponseCodeData[] = [
  { code: 'API-00000', id: ResponseCode.Success, description: 'Success' },

  { code: 'API-40001', id: ResponseCode.ImageFileMissing, description: 'Image file not sent' },
  { code: 'API-40002', id: ResponseCode.IncorrectInputParams, description: 'Incorrect path or query parameters passed to the API' },
  { code: 'API-40003', id: ResponseCode.InvalidTestkitID, description: 'Testkit ID provided is invalid' },
  { code: 'API-40004', id: ResponseCode.EmptyTestkitData, description: 'Testkit CSV data provided is missing or corrupted' },
  { code: 'API-40005', id: ResponseCode.InvalidTestkitCreate, description: 'Testkit doesn\'t exist. New test kit can only be created using /api/v1/secure/testkit/new API' },
  { code: 'API-40006', id: ResponseCode.InvalidTestkitUpdate, description: 'Invalid test kit update' },
  { code: 'API-40007', id: ResponseCode.InvalidTestkitStock, description: 'Testkit in STOCK status contains invalid attributes' },
  { code: 'API-40008', id: ResponseCode.InvalidTestkitIssue, description: 'Testkit being issued does not provide mandatory patient data' },
  { code: 'API-40009', id: ResponseCode.MidwifeDoesNotExist, description: 'Missing midwife data' },
  { code: 'API-40013', id: ResponseCode.InvalidKitStatus, description: 'Status of all kits must be \'ALLOCATED\'' },

  { code: 'API-40101', id: ResponseCode.WrongUsernamePassword, description: 'Wrong username/password' },
  { code: 'API-40102', id: ResponseCode.WrongEmailFormat, description: 'Incorrect email format' },
  { code: 'API-40103', id: ResponseCode.JwtSignatureException, description: 'JWT signature exception' },
  { code: 'API-40104', id: ResponseCode.JwtMalformedTokenException, description: 'JWT malformed token' },
  { code: 'API-40105', id: ResponseCode.JwtTokenExpiredException, description: 'JWT token expired' },
  { code: 'API-40106', id: ResponseCode.JwtTokenUnsupportedException, description: 'JWT token unsupported' },
  { code: 'API-40107', id: ResponseCode.JwtTokenMissingException, description: 'JWT token missing' },
  { code: 'API-40108', id: ResponseCode.UsernameEmailUnverified, description: 'Email address has not been verified' },
  { code: 'API-40109', id: ResponseCode.IncorrectActivationCode, description: 'Inncorrect account activation code' },
  { code: 'API-40110', id: ResponseCode.UsernameBarred, description: 'Account barred' },
  { code: 'API-40111', id: ResponseCode.OperationNotPermitted, description: 'This operation is not permitted for this user' },
  { code: 'API-40112', id: ResponseCode.WrongApiKeySecret, description: 'Unknown API key/secret' },
  { code: 'API-40113', id: ResponseCode.PasswordPolicyException, description: 'Password provided does not comply with password policy' },

  { code: 'API-40401', id: ResponseCode.EntityNotFound, description: 'Entity not found for the given key' },
  { code: 'API-40402', id: ResponseCode.AccountDataError, description: 'Account data incorrect' },

  { code: 'API-40901', id: ResponseCode.UserAlreadyExists, description: 'User with a given username already exists' },
  { code: 'API-40902', id: ResponseCode.UserNotMedic, description: 'User provided for the operation is not in the MEDIC role' },

  { code: 'API-41201', id: ResponseCode.InsufficientTestkitsInStock, description: 'Insufficient testkits in stock for desired allocation' },
  { code: 'API-41202', id: ResponseCode.TestkitNotAllocated, description: 'Provided testkit isn\'t in ALLOCATED status' },
  { code: 'API-41203', id: ResponseCode.TestkitWrongMidwife, description: 'Provided testkit is allocated to a different midwife' },
  { code: 'API-41204', id: ResponseCode.TestkitIssuingError, description: 'Error while issuing a testkit. Try again with a different testkit ID' },

  { code: 'API-50001', id: ResponseCode.ImageUploadError, description: 'Image file saving error' },
  { code: 'API-50002', id: ResponseCode.CsvDownloadError, description: 'System error generating CSV file' },
];

export const getResponseCodeData = (apiResponse?: any) => {
  let codeData;

  if (apiResponse?.code) {
    codeData = RESPONSE_CODE_DATA.find(response => response.code === apiResponse.code)
  }

  if (!codeData) {
    console.warn(`Unknown response code: ${apiResponse?.code}`)
    codeData = {
      id: ResponseCode.Unknown,
      code: apiResponse?.code,
      description: apiResponse?.description,
    } as ResponseCodeData;
  }

  return codeData;
}

export const getResponseCodeByCode = (code: string | undefined) => {
  return code ? RESPONSE_CODE_DATA.find(responseCode => responseCode.code === code) : undefined;
}

export const getResponseCodeByID = (id: ResponseCode | undefined) => {
  return id !== undefined ? RESPONSE_CODE_DATA.find(responseCode => responseCode.id === id) : undefined;
}

import AutoSizer from 'react-virtualized-auto-sizer';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import { TableProps, TableRendererProps } from './types';
import { BLANK } from '../../utils/global';
import { headerRenderer, tableRenderer } from './renderers';

const Table = <T extends object>({
  width,
  height,
  headerHeight = 54,
  rowHeight = 36,
  marginLeft = 24,
  marginRight = 24,
  columnSpacing = 12,
  columns,
  items,
  selectedItemIdx = -1,
  isLoading,
  noResultsText = BLANK,
  sorter,
  rowRenderer,
  getRowTextColour,
  onClickRow,
  sx,
}: TableProps<T>) => {
  const theme = useTheme();

  const rowWidth = width || columns.reduce<number>((total, column) => {
    return columns ? total + column.width + columnSpacing : total;
  }, marginLeft + marginRight - columnSpacing);

  const tableRendererProps: TableRendererProps<T> = {
    columns,
    items,
    selectedItemIdx,
    tableHeight: 0,
    rowWidth,
    rowHeight,
    columnSpacing,
    ml: marginLeft,
    mr: marginRight,
    theme,
    rowRenderer,
    getRowTextColour,
    onClickRow,
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      flex: '1 1 auto',
      overflow: 'auto hidden',
      ...sx,
    }}>
      {headerRenderer({
        columns,
        rowWidth,
        headerHeight,
        columnSpacing,
        ml: marginLeft,
        mr: marginRight,
        sorter,
        theme,
      })}

      {(isLoading || items.length === 0) ? (isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: height, ml: `${marginLeft}px` }}>
          <CircularProgress size={60}/>
        </Box>
      ) : (
        <Typography variant='h6' sx={{ width: rowWidth - marginLeft - marginRight, ml: `${marginLeft}px`, mr: `${marginRight}px`, mt: 1 }}>
          {noResultsText}
        </Typography>
      )) : height ? tableRenderer({ ...tableRendererProps, tableHeight: height }) : (
        <Box sx={{ flex: '1 1 auto' }}>
          <AutoSizer>
            {({ height }) => tableRenderer({ ...tableRendererProps, tableHeight: height })}
          </AutoSizer>
        </Box>
      )}
    </Box>
  );
}

export default Table;
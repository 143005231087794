import React from "react";
import { Box, Button, Divider, Dialog as MuiDialog, SxProps, Typography } from "@mui/material";
import { Form, Formik, FormikConfig, FormikValues } from "formik";
import { DialogCloseHandler } from "../../types/dialog";

const BaseFormDialog = <T extends FormikValues>({
  open,
  onClose,
  title,
  confirmText = 'Confirm',
  confirmIcon,
  confirmDisabled = false,
  cancelText = 'Cancel',
  validateOnChange = false,
  validateOnBlur = false,
  width = 348,
  sx,
  children,
  ...props    // Formik-related props are passed through to the Formik component
}: {
  open: boolean,
  onClose?: DialogCloseHandler,
  title?: string,
  confirmText?: string,
  confirmIcon?: React.ReactNode,
  confirmDisabled?: boolean,
  cancelText?: string,
  width?: number,
  sx?: SxProps,
} & FormikConfig<T>) => {

  return (
    <MuiDialog open={open} onClose={onClose} PaperProps={{ sx: { width: width, overflowY: 'visible', ...sx }}}>
      <Formik
        validateOnChange={validateOnChange}
        validateOnBlur={validateOnBlur}
        {...props}
      >
        {(formProps) => (
          <Form noValidate onSubmit={formProps.handleSubmit}>
            <Box sx={{ display: 'flex', flexDirection: 'column', px: 3, pt: 2, pb: 3 }}>
              {title && <Typography sx={{ mb: 2 }}>{title}</Typography>}

              {React.isValidElement(children) ? children : (children as (args: any) => React.ReactNode)(formProps)}
            </Box>
      
            <Divider/>

            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', px: 3, py: 1 }}>
              <Button variant='text' onClick={onClose ? (e => onClose(e, 'closeClick')) : undefined} sx={theme => ({ mr: 1, color: theme.palette.text.secondary })}>{cancelText}</Button>
              <Button variant='text' disabled={confirmDisabled} type='submit' endIcon={confirmIcon}>{confirmText}</Button>
            </Box>
          </Form>
        )}
      </Formik>
    </MuiDialog>
  );
}

export default BaseFormDialog;
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import type { RootState } from "../store";
import { convertFilterToAPI, convertSorterToAPI, Testkit, TestkitAPIStatus, TestkitUIFilter, TestkitUISorter } from "../../types/testkit";
import TestkitAPI from "../../api/endpoints/testkit";
import { ApiErrorResponse, EndpointRequest } from "../../api/types/base";
import { selectRole } from "./account";
import { showErrorMessage } from "../utils";
import { Toast } from "../../components/Toast";
import { removeDummyKit, removePatientIdPrefixTestkits } from "../../utils/testkit";
import { COOKIE_ROLE } from "../../utils/global";

interface TestkitState {
  stockTestkitFilter: TestkitUIFilter,
  stockTestkitSorter: TestkitUISorter,
  isLoadingStockTestkits: boolean,
  stockTestkits: Testkit[],
  selectedStockTestkit?: Testkit,

  isLoadingAvailableTestkits: boolean,
  availableTestkits: Testkit[],
}

const cookies = new Cookies(undefined, { path: '/', sameSite: 'lax' });

const initialState: TestkitState = {
  stockTestkitFilter: { idPattern: '', status: '', dispatched: false, includeCompleted: false },
  stockTestkitSorter: { column: 'status', dir: 'asc' },
  isLoadingStockTestkits: false,
  stockTestkits: [],
  selectedStockTestkit: undefined,

  isLoadingAvailableTestkits: false,
  availableTestkits: [],
}

// NOTE: 'state' is only mutable inside 'createSlice'
export const testkitSlice = createSlice({
  name: 'testkits',
  initialState,
  reducers: {
    setStockFilter: (state, action: PayloadAction<TestkitUIFilter>) => {
      state.stockTestkitFilter = action.payload;
    },
    setStockSorter: (state, action: PayloadAction<TestkitUISorter>) => {
      state.stockTestkitSorter = action.payload;
    },
    setSelectedStockTestkit: (state, action: PayloadAction<Testkit | undefined>) => {
      state.selectedStockTestkit = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Thunk: getStockTestkits
    builder.addCase(getStockTestkits.pending, (state, action) => {
      state.isLoadingStockTestkits = true;

      // If specified, update stored filter
      if (action.meta.arg.updateFilter) {
        state.stockTestkitFilter = action.meta.arg.filter || {...initialState.stockTestkitFilter};
      }
      
      // If specified, update stored sorter
      if (action.meta.arg.updateSorter) {
        state.stockTestkitSorter = action.meta.arg.sorter || {...initialState.stockTestkitSorter};
      }
    });
    builder.addCase(getStockTestkits.fulfilled, (state, action) => {
      state.isLoadingStockTestkits = false;
      state.selectedStockTestkit = undefined;

      const testkits = action.payload?.data || [];
      removePatientIdPrefixTestkits(testkits);
      removeDummyKit(testkits, cookies.get(COOKIE_ROLE) || undefined);
      state.stockTestkits = testkits;
    });
    builder.addCase(getStockTestkits.rejected, (state, action) => {
      state.isLoadingStockTestkits = false;

      showErrorMessage(action.payload as ApiErrorResponse, { title: 'Failed to retrieve testkit stock data' });
    });

    // Thunk: getAvailableTestkits
    builder.addCase(getAvailableTestkits.pending, (state, action) => { state.isLoadingAvailableTestkits = true; });
    builder.addCase(getAvailableTestkits.fulfilled, (state, action) => {
      state.isLoadingAvailableTestkits = false;

      const testkits = action.payload?.data || [];
      removePatientIdPrefixTestkits(testkits);
      removeDummyKit(testkits, cookies.get(COOKIE_ROLE) || undefined);
      state.availableTestkits = testkits;
    });
    builder.addCase(getAvailableTestkits.rejected, (state, action) => {
      state.isLoadingAvailableTestkits = false;
      showErrorMessage(action.payload as ApiErrorResponse, { title: 'Failed to retrieve available testkits' });
    });

    // Thunk: generateNewIDs
    // builder.addCase(generateNewIDs.pending, (state, action) => {});
    builder.addCase(generateNewIDs.fulfilled, (state, action) => {
      Toast.success({ title: `Generated ${action.meta.arg.count} new Study IDs` });
    });
    builder.addCase(generateNewIDs.rejected, (state, action) => {
      showErrorMessage(action.payload as ApiErrorResponse, { title: `Failed to generate ${action.meta.arg.count} new Study IDs` });
    });

    // Thunk: requestStock
    // builder.addCase(requestStock.pending, (state, action) => {});
    builder.addCase(requestStock.fulfilled, (state, action) => {
      Toast.success({ title: `Allocated ${action.meta.arg.count} testkits` });
    });
    builder.addCase(requestStock.rejected, (state, action) => {
      showErrorMessage(action.payload as ApiErrorResponse, { title: `Unable to allocate ${action.meta.arg.count} testkits` });
    });
  },
});

export default testkitSlice.reducer;
export const {
  setStockFilter,
  setStockSorter,
  setSelectedStockTestkit,
} = testkitSlice.actions;

export const getStockTestkits = createAsyncThunk('testkit/getStockTestkits',
  async ({ filter, sorter, signal }: {
    filter?: TestkitUIFilter,
    sorter?: TestkitUISorter,
    updateFilter?: boolean,
    updateSorter?: boolean,
  } & EndpointRequest, { getState, rejectWithValue }) => {
    const state = getState() as RootState;

    const apiFilter = filter || selectStockTestkitFilter(state);
    const apiSorter = sorter || selectStockTestkitSorter(state);

    return TestkitAPI.getAllKits({
      signal,
      limit: 200,
      ...convertFilterToAPI(apiFilter, selectRole(state)),
      ...convertSorterToAPI(apiSorter),
    })
    .catch(error => rejectWithValue(error));
  },
);

export const getAvailableTestkits = createAsyncThunk('testkit/getAvailableTestkits',
  async ({ siteId, signal }: { siteId?: string } & EndpointRequest, { rejectWithValue }) => (
    TestkitAPI.getAllKits({ signal, status: TestkitAPIStatus.ALLOCATED, siteId, dispatched: true })
    .catch(error => rejectWithValue(error))
  ),
);

export const generateNewIDs = createAsyncThunk('testkit/generateNewIDs',
  async ({ count, signal }: { count: number } & EndpointRequest, { rejectWithValue }) => (
    TestkitAPI.generateNewKits({ count, signal })
    .catch(error => rejectWithValue(error))
));

export const requestStock = createAsyncThunk('testkit/requestStock',
  async ({ count, siteId, signal }: { count: number, siteId?: string } & EndpointRequest, { rejectWithValue }) => (
    TestkitAPI.allocateKitsToMidwife({ count, siteId, signal})
    .catch(error => rejectWithValue(error))
));

export const selectStockTestkitFilter = (state: RootState) => state.testkit.stockTestkitFilter;
export const selectStockTestkitSorter = (state: RootState) => state.testkit.stockTestkitSorter;
export const selectIsLoadingStockTestkits = (state: RootState) => state.testkit.isLoadingStockTestkits;
export const selectStockTestkits = (state: RootState) => state.testkit.stockTestkits;
export const selectSelectedStockTestkit = (state: RootState) => state.testkit.selectedStockTestkit;

export const selectIsLoadingAvailableTestkits = (state: RootState) => state.testkit.isLoadingAvailableTestkits;
export const selectAvailableTestkits = (state: RootState) => state.testkit.availableTestkits;

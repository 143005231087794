import React from "react";
import { FormControl, RadioGroup as MuiRadioGroup, SxProps, Typography } from "@mui/material";

const RadioGroup = ({
  disabled,
  row = false,
  name,
  label,
  value,
  onChange,
  children,
  sx,
}: {
  disabled?: boolean,
  row?: boolean,
  name?: string,
  label?: string,
  value?: any,
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void,
  children: React.ReactNode,
  sx?: SxProps,
}) => {
  return (
    <FormControl disabled={disabled} sx={sx}>
      {label && (
        <Typography variant='h6' sx={{ mb: 1 }}>{label}</Typography>
      )}

      <MuiRadioGroup row={row} name={name} value={value} onChange={onChange} sx={{ gap: 1 }}>
        {children}
      </MuiRadioGroup>
    </FormControl>
  );
}

export default RadioGroup;
import React from 'react';
import { Box, Divider, SxProps, Theme, Tooltip, Typography } from '@mui/material';
import { AccessTime as DelayedIcon, Error as InvalidIcon } from '@mui/icons-material';
import { useDispatch, useSelector } from '../../redux/hooks';
import { selectMidwives, selectRole, selectSiteNames } from '../../redux/slices/account';
import { clearPatientImages, getAllImages, getPatients, selectIsLoadingPatients, selectPatientSorter, selectPatients, selectSelectedPatient, setSelectedPatient } from '../../redux/slices/patient';
import { Patient, PatientFilter } from '../../types/patient';
import { AccountData, AccountRole } from '../../types/account';
import { BLANK, getAccountFullName, removeUndefined } from '../../utils/global';
import { pregnancyWeekToString, isoDateTimeToDate, getDiffDays } from '../../utils/dateTime';
import { Column } from '../../components/Table/types';
import Section from '../../components/Section';
import Table from '../../components/Table';
import SearchFilter from '../../components/SearchFilter';
import { DateTime } from 'luxon';

type FilterValues = {
  id: string,
  midwife: AccountData | null,
  incFinished: boolean,
}

const DAY_LIMIT_WARNING = 7;
const DAY_LIMIT_ERROR   = 14;

const ROW_ID = 130;
const ROW_ID_WIDE = 300;
const ROW_SITE = 200;
const ROW_DUE_DATE = 110;
const ROW_WEEK_IDX = 70;
// const ROW_MISSED_SAMPLES = 90;
const ROW_LAST_SUBMISSION = 100;
const ROW_ERROR = 24;

const getLastSubmitText = (patient: Patient) => {
  const daysSinceSubmit = patient.lastImageTimestamp && getDiffDays(patient.lastImageTimestamp);

  if (daysSinceSubmit === undefined) {
    return BLANK;
  }
  else if (daysSinceSubmit === 0) {
    return 'Today';
  }
  else {
    return daysSinceSubmit === 1 ? '1 day ago' : `${daysSinceSubmit} days ago`;
  }
}

const getRowError = (patient: Patient) => {
  if (patient.finished) {
    return;
  }

  const daysSinceStart = getDiffDays(patient.startTimestamp);
  const daysSinceSubmit = patient.lastImageTimestamp ? getDiffDays(patient.lastImageTimestamp) : -1;
  const isLastSubmitRecent = patient.lastImageTimestamp ? (Math.abs(DateTime.fromISO(patient.lastImageTimestamp, {zone: 'utc'}).diffNow('minutes').minutes) < 30) : false;

  let errorType: 'invalid' | 'delayed' | undefined = undefined;
  let errorColour: 'error' | 'warning' | undefined = undefined;
  let tooltip: string | undefined = undefined;

  // No submission 2+ weeks after starting
  if (daysSinceSubmit < 0 && daysSinceStart > DAY_LIMIT_ERROR) {
    errorType = 'delayed';
    errorColour = 'error';
    tooltip = 'Started 2+ weeks ago - no submission yet';
  }
  // Last submission 2+ weeks ago
  else if (daysSinceSubmit > DAY_LIMIT_ERROR) {
    errorType = 'delayed';
    errorColour = 'error';
    tooltip = 'Last submission was 2+ weeks ago';
  }
  // Last submission was not valid & more than 30 minutes ago (to give server time to process)
  else if ((patient.lastImageTimestamp !== patient.lastSuccessfulProcessingTimestamp) && !isLastSubmitRecent) {
    errorType = 'invalid';
    errorColour = 'error';
    tooltip = 'Last submission was invalid';
  }
  // No submission 1+ weeks after starting
  else if (daysSinceSubmit < 0 && daysSinceStart > DAY_LIMIT_WARNING) {
    errorType = 'delayed';
    errorColour = 'warning';
    tooltip = 'Started 1+ week ago - no submission yet';
  }
  // Last submission 1+ weeks ago
  else if (daysSinceSubmit > DAY_LIMIT_WARNING) {
    errorType = 'delayed';
    errorColour = 'warning';
    tooltip = 'Last submission was 1+ week ago';
  }

  if (!!errorType) {
    return (
      <Tooltip title={tooltip} placement='right' disableInteractive>
        {errorType === 'invalid' ? <InvalidIcon color={errorColour}/> : <DelayedIcon color={errorColour}/>}
      </Tooltip>
    )
  }
}

const BASE_COLUMNS: Column<Patient>[] = [
  { id: 'id', label: 'Study ID', width: ROW_ID, getValue: (patient: Patient) => patient.id },
  { id: 'site', label: 'Site', width: ROW_SITE },
  { id: 'dueDate', label: 'Due Date (Ultrasound)', width: ROW_DUE_DATE, getValue: (patient: Patient) => patient?.dueDate ? isoDateTimeToDate(patient.dueDate) : BLANK },
  { id: 'weekIdx', label: 'Week', width: ROW_WEEK_IDX, getValue: (patient: Patient) => patient?.week !== undefined ? pregnancyWeekToString(patient.week) : BLANK },
  // { id: 'missedSamples', label: 'Missed Samples', width: ROW_MISSED_SAMPLES, getValue: (patient: Patient) => BLANK },
  { id: 'lastSubmission', label: 'Last Submission', width: ROW_LAST_SUBMISSION, getValue: (patient: Patient) => getLastSubmitText(patient) },
  { id: 'error', label: undefined, width: ROW_ERROR, getValue: (patient: Patient) => getRowError(patient) },
];

const TableSection = ({
  sx,
}: {
  sx?: SxProps<Theme>,
}) => {
  const dispatch = useDispatch();
  const reduxSorter = useSelector(selectPatientSorter);
  const patients = useSelector(selectPatients);
  const selectedPatient = useSelector(selectSelectedPatient);
  const isLoadingPatients = useSelector(selectIsLoadingPatients);
  const midwives = useSelector(selectMidwives);

  const role = useSelector(selectRole);
  const sites = useSelector(selectSiteNames);

  const [columns, setColumns] = React.useState<Column<Patient>[]>([]);

  // De-select User when unloading the User screen
  React.useEffect(() => {
    return () => {
      dispatch(setSelectedPatient(undefined));
    }
  }, [dispatch]);

  // Fetch Testkit data
  React.useEffect(() => {
    const controller = new AbortController();
    // When this screen loads -> fetch stock testkit data based on existing filter/sorting (if any)
    dispatch(getPatients({ signal: controller.signal }));
    return () => { controller.abort() }
  }, [dispatch]);

  // Setup columns
  React.useEffect(() => {
    let cols = [ ...BASE_COLUMNS ];

    if (role !== AccountRole.MEDIC) {
      cols[0].width = ROW_ID;
      cols[1].getValue = (item: Patient) => (item.site && sites[item.site]) || BLANK;
    }
    else {
      // Remove the 'Sites' column
      cols.splice(1, 1);
      cols[0].width = ROW_ID_WIDE;
    }

    cols[0].onClick = handleClickColumn;
    setColumns(cols);
  }, [role, sites, reduxSorter]);

  const getRowTextColour = ({ item, theme }: { item: Patient, theme: Theme }) => {
    if (item.id === process.env.REACT_APP_DUMMY_KIT) {
      return theme.palette.info.main;
    }
    else if (item.finished) {
      return theme.palette.text.disabled;
    }
  }

  const handleSearch = (values: FilterValues) => {
    dispatch(getPatients({ filter: removeUndefined({
      idPattern: values.id || undefined,
      midwife: values.midwife?.username || undefined,
      includeCompleted: values.incFinished,
    } as PatientFilter)}));
  }

  const handleClickRow = ({ index }: { index: number }) => {
    const patient = selectedPatient !== patients[index] ? patients[index] : undefined;
    dispatch(setSelectedPatient(patient));

    if (!!patient) {
      dispatch(getAllImages({ id: patient.id }));
    }
    else {
      dispatch(clearPatientImages());
    }
  }

  const handleClickColumn = ({ id }: { id: string }) => {
    // Clicked existing sort param -> change direction
    if (reduxSorter.column === id) {
      dispatch(getPatients({ sorter: { column: reduxSorter.column, dir: reduxSorter.dir === 'asc' ? 'desc' : 'asc' }}));
    }
    else {
      dispatch(getPatients({ sorter: { column: id as any, dir: 'asc' }}));
    }
  }

  return (
    <Section sx={{ height: '100%', p: 0, ...sx }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'start', overflowX: 'auto', pl: 3, pr: 1, py: 2 }}>
        <SearchFilter
          initialValues={{ id: '', midwife: null, incFinished: false } as FilterValues}
          filters={[
            { type: 'text', id: 'id', label: 'Study ID' },
            { type: 'options', id: 'midwife', label: 'Midwife', options: midwives, width: 240,
              getLabel: (midwife: AccountData) => getAccountFullName(midwife),
              isEqual: (a: AccountData, b: AccountData) => a.username === b.username,
            },
            { type: 'boolean', id: 'incFinished', label: 'Finished' },
          ]}
          onSearch={handleSearch}
          sx={{ mr: 1 }}
        />
      </Box>

      <Table
        columns={columns}
        items={patients}
        sorter={reduxSorter}
        selectedItemIdx={patients.findIndex(kit => kit === selectedPatient)}
        isLoading={isLoadingPatients}
        noResultsText='No Users Found'
        onClickRow={handleClickRow}
        getRowTextColour={getRowTextColour}
      />

      <Divider/>
      <Box sx={{ px: 3, py: 1 }}>
        <Typography color={theme => theme.palette.text.secondary}>Count: {patients.length}</Typography>
      </Box>
    </Section>
  );
}

export default TableSection;
import React from "react";
import { Autocomplete, Box, Checkbox, FormControlLabel, SxProps, TextField } from "@mui/material";
import {
  Search as SearchIcon,
  Clear as ClearIcon,
} from '@mui/icons-material';
import IconButton from "./IconButton";
import { FormikValues } from "formik";
import { DatePicker } from "@mui/x-date-pickers";

export type FilterText = { type: 'text', label: string, width?: number, disableSearchOnEnterKey?: boolean }
export type FilterBoolean = { type: 'boolean', label: string, override?: boolean }
export type FilterDate = { type: 'date', label: string }
export type FilterOptions<TOption> = { type: 'options', label: string, width?: number, options: TOption[], getLabel?: (value: TOption) => string, isEqual?: (a: TOption, b: TOption) => boolean }

export type Filter<T> = {
  id: string,
  disabled?: boolean | ((values: T) => boolean),
  visible?: boolean,
} & (FilterText | FilterBoolean | FilterDate | FilterOptions<any>);

const SearchFilter = <T extends FormikValues> ({
  initialValues,
  filters,
  showClear = true,
  onSearch,
  sx,
}: {
  initialValues: T,
  filters: Filter<T>[],
  showClear?: boolean,
  onSearch?: (values: T) => void,
  sx?: SxProps,
}) => {
  const [values, setValues] = React.useState<T>(initialValues);
  const [canClear, setCanClear] = React.useState(false);

  React.useEffect(() => {
    const keys = Object.keys(initialValues)
    let anyDiff = false;
    
    // Check if any values are different to their initial value
    for (let i = 0; i < keys.length; i++) {
      if (initialValues[keys[i]] !== values[keys[i]]) {
        anyDiff = true;
        break;
      }
    }
    
    // If any are different -> show clear button, otherwise hide it
    if (anyDiff !== canClear) {
      setCanClear(anyDiff);
    }
  }, [initialValues, values, canClear, setCanClear]);

  const handleChangeValue = (id: string, value: any, triggerSearch: boolean = false) => {
    const newValues = { ...values, [id]: value };
    setValues(newValues);

    if (triggerSearch) {
      onSearch?.(newValues);
    }
  }

  const handleClearValues = (triggerSearch: boolean = true) => {
    const newValues = { ...initialValues };
    setValues(newValues);

    if (triggerSearch) {
      onSearch?.(newValues);
    }
  }

  const getFilterComponent = (filter: Filter<T>) => {
    if (filter.visible !== undefined && !filter.visible) {
      return <React.Fragment key={filter.id}/>
    }

    const disabled = !!filter.disabled && (typeof(filter.disabled) === 'function' ? filter.disabled(values) : filter.disabled)

    switch (filter.type) {
      case 'text':
        return (
          <TextField
            key={filter.id}
            disabled={disabled}
            label={filter.label}
            value={values[filter.id]}
            onChange={e => handleChangeValue(filter.id, e.target.value)}
            onKeyDown={e => { if (!filter.disableSearchOnEnterKey && e.key === 'Enter') { onSearch?.(values) }}}
            sx={{ width: `${filter.width || 160}px` }}
          />
        );
      case 'boolean':
        return (
          <FormControlLabel
            key={filter.id}
            disabled={disabled || filter.override !== undefined}
            label={filter.label}
            control={<Checkbox
              checked={filter.override ?? values[filter.id]}
              onChange={(_, val) => handleChangeValue(filter.id, val, true)}
            />}
            sx={{ mx: 0 }}
          />
        );
      case 'date':
        return (
          <DatePicker
            key={filter.id}
            disabled={disabled}
            label={filter.label}
            value={values[filter.id]}
            onChange={val => handleChangeValue(filter.id, val, true)}
            sx={{ width: 170 }}
          />
        );
      case 'options':
        return (
          <Autocomplete
            disablePortal
            key={filter.id}
            disabled={disabled}
            options={filter.options}
            value={values[filter.id]}
            onChange={(_, val) => handleChangeValue(filter.id, val, true)}
            getOptionLabel={filter.getLabel}
            isOptionEqualToValue={filter.isEqual}
            sx={{ width: `${filter.width || 200}px` }}
            renderInput={params => <TextField {...params} label={filter.label}/>}
          />
        );
    }
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, ...sx }}>
      <IconButton variant='contained' title='Filter results' onClick={() => onSearch?.(values)} sx={{ mr: 1 }}><SearchIcon/></IconButton>
      
      {filters.map(filter => getFilterComponent(filter))}

      {showClear && canClear && (
        <IconButton variant='icon' onClick={() => handleClearValues()}><ClearIcon/></IconButton>
      )}
    </Box>
  );
}

export default SearchFilter;
import { Box, Typography } from "@mui/material";

const QuestionAnswer = ({
  question,
  answer,
}: {
  question: string,
  answer: string | string[],
}) => {
  return (
    <Box>
      <Typography variant='h6' sx={theme => ({ mt: 1, color: theme.palette.text.secondary })}>{question}</Typography>

      {typeof(answer) === 'string' ? (
        <Typography sx={theme => ({ mt: 1, color: theme.palette.text.primary })}>{answer}</Typography>
      ) : answer.map(line => (
        <Typography sx={theme => ({ mt: 1, color: theme.palette.text.primary })}>{line}</Typography>
      ))}
    </Box>
  );
}

export default QuestionAnswer;
import { Box, FormControlLabel, Radio, Typography } from "@mui/material";

const RadioButton = ({
  disabled,
  label,
  labelHint,
  value,
}: {
  disabled?: boolean,
  label: string,
  labelHint?: string,
  value?: unknown,
}) => {
  const child = !labelHint ? label : (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography variant='body1'>{label}</Typography>
      <Typography variant='caption' color={theme => theme.palette.text.secondary}>{labelHint}</Typography>
    </Box>
  )
 
  return (
    <FormControlLabel disabled={disabled} value={value} control={<Radio/>} label={child} sx={{ width: 'fit-content' }}/>
  );
}

export default RadioButton;
import { DateTime } from "luxon";
import { CrfBaselineInfoApi, CrfClinicalDiagnosticsApi, CrfData, CrfDataApi } from "../types/crf";
import { removeUndefined } from "./global";
import { Patient } from "../types/patient";

export const REGEX_INT_2 = /^\d{0,2}$/;
export const REGEX_INT_3 = /^\d{0,3}$/;
export const REGEX_INT_4 = /^\d{0,4}$/;
export const REGEX_FLOAT_3_1 = /^\d{0,3}(\.\d?)?$/;
export const REGEX_FLOAT_1_2 = /^\d{0,1}(\.\d{0,2})?$/;
export const REGEX_FLOAT_1_3 = /^\d{0,1}(\.\d{0,3})?$/;

const PREFIX_BASELINE = 'base_';
const PREFIX_OBSTETRIC = 'obs_';
const PREFIX_MEDICAL = 'med_';
const PREFIX_SMOKING = 'smoke_';
const PREFIX_CLINICAL_DIAG = 'clin_';
const PREFIX_PRE_ECLAMPSIA = 'pe_';
const PREFIX_MATERNAL = 'maternal_';
const PREFIX_ULTRASOUND = 'ultra_';
const PREFIX_NEONATAL = 'neonatal_';
const PREFIX_BIRTH = 'birth_';

const PARAMS_NUMBERS = [
  'base_maternalAge',
  'base_height',
  'base_weight',

  'clin_sbp',
  'clin_dbp',
  'clin_pappAMom',
  'clin_crl',
  'clin_uterineArteryPiLeft',
  'clin_uterineArteryPiRight',
  
  'ultra_gestationalAge',
  'ultra_estimatedFetalWeight',
  'ultra_umbilicalArteryPi',
  'ultra_cerebralArteryPindex',
  'ultra_uterineArteryPiLeft',
  'ultra_uterineArteryPiRight',

  'birth_birthOffset',
  'birth_steroidDoses',
  'birth_steroidDays',
  'birth_weight',

  'neonatal_daysOnVentilation',
];

const PARAMS_DATE = [
  'base_estimatedDueDate',
  'base_heightWeightTakenDate',

  'clin_bloodPressureTestDate',
  'clin_pappATestDate',
  'clin_crlTestDate',
  'clin_preeclampsiaWithProteinuriaDate',
  'clin_severeHypertensionDate',
  'clin_severePreeclampsiaWithSymptomsDate',
  'clin_severePreeclampsiaWithBiochemicalDate',
  'clin_severePreeclampsiaWithHaematologicalDate',
  'clin_eclampsiaDate',
  'clin_firstMildPregnancyHypertensionDate',
  'clin_firstModeratePregnancyHypertensionDate',
  'clin_firstSeverePregnancyHypertensionDate',
  'clin_anyUrinaryDate',
  'clin_significantUrinaryProteinDate',
  'clin_hellpDate',

  'pe_headacheDate',
  'pe_blurredVisionDate',
  'pe_epigastricPainDate',
  'pe_vomittingDate',
  'pe_oedemaDate',

  'ultra_scanDate',
  'birth_dateOfBirth',
  'neonatal_nicuAdmissionDate',
];

export const convertCrfToApi = ({ patient, crf }: { patient?: Patient, crf: CrfData }) => {
  const data: CrfData = {
    ...crf,
    base_estimatedDueDate: convertDateToUi(patient?.dueDate),
  };
  
  // Convert data types
  convertDataTypes(data, true);

  // Inflate object layout
  return inflateCrfData(data);
}

export const convertCrfToUi = (input: CrfDataApi) => {
  // Flatten object layout
  const flattened = flattenCrfDataApi(input);
  
  // Convert data types
  convertDataTypes(flattened, false);

  return removeUndefined(flattened) as CrfData;
}

const convertDataTypes = (data: any, toApi: boolean) => {
  // For all numbers in input, apply appropriate number converter
  PARAMS_NUMBERS.forEach(param => {
    if (data[param] !== undefined) data[param] = toApi ? convertNumberToApi(data[param]) : convertNumberToUi(data[param])
  })

  // For all dates in input, apply appropriate date converter
  PARAMS_DATE.forEach(param => {
    if (data[param] !== undefined) data[param] = toApi ? convertDateToApi(data[param]) : convertDateToUi(data[param])
  })
}

const convertNumberToApi = (value?: string) => value ? Number(value) : undefined;
const convertNumberToUi = (value?: number) => value ? value.toString() : undefined;  // Ignore 0

export const convertDateToApi = (date?: DateTime) => date?.toISODate();
export const convertDateToUi = (date?: string) => date ? DateTime.fromISO(date) : undefined;

const flattenCrfDataApi = (input: CrfDataApi) => {
  const output: any = { id: input.id };

  // flattenFlatApiObj(output, PREFIX_BASELINE, input?.baselineInformation);
  input?.baselineInformation && flattenCrfBaselineApi(input.baselineInformation, output);
  input?.clinicalDiagnostics && flattenCrfClinicalDiagnosticsApi(input.clinicalDiagnostics, output);

  return output;
}

const flattenCrfBaselineApi = (input: CrfBaselineInfoApi, output: any) => {
  flattenFlatApiObj(output, PREFIX_OBSTETRIC, input?.obstetricHistory);
  flattenFlatApiObj(output, PREFIX_MEDICAL, input?.medicalHistory);
  flattenFlatApiObj(output, PREFIX_SMOKING, input?.smokingHistory);

  const flatBaseline = { ...input };
  delete flatBaseline.obstetricHistory;
  delete flatBaseline.medicalHistory;
  delete flatBaseline.smokingHistory;
  flattenFlatApiObj(output, PREFIX_BASELINE, flatBaseline);
}

const flattenCrfClinicalDiagnosticsApi = (input: CrfClinicalDiagnosticsApi, output: any) => {
  flattenFlatApiObj(output, PREFIX_PRE_ECLAMPSIA, input?.preeclampsiaSymptoms);
  flattenFlatApiObj(output, PREFIX_MATERNAL, input?.maternalOutcomes);
  flattenFlatApiObj(output, PREFIX_ULTRASOUND, input?.lastUltrasoundData);
  flattenFlatApiObj(output, PREFIX_NEONATAL, input?.neonatalOutcomes);
  flattenFlatApiObj(output, PREFIX_BIRTH, input?.birthData);

  const flatClinicalDiag = { ...input };
  delete flatClinicalDiag.preeclampsiaSymptoms;
  delete flatClinicalDiag.maternalOutcomes;
  delete flatClinicalDiag.lastUltrasoundData;
  delete flatClinicalDiag.neonatalOutcomes;
  delete flatClinicalDiag.birthData;
  flattenFlatApiObj(output, PREFIX_CLINICAL_DIAG, flatClinicalDiag);
}

const flattenFlatApiObj = (output: any, prefix: string, obj?: any) => {
  if (!!obj) {
    Object.keys(obj).forEach(key => {
      output[`${prefix}${key}`] = obj[key];
    })
  }
}

const inflateCrfData = (input: any) => {
  const baseline = inflateFlatUiObj(input, PREFIX_BASELINE);
  const obstetric = inflateFlatUiObj(input, PREFIX_OBSTETRIC);
  const medical = inflateFlatUiObj(input, PREFIX_MEDICAL);
  const smoking = inflateFlatUiObj(input, PREFIX_SMOKING);

  const clinical = inflateFlatUiObj(input, PREFIX_CLINICAL_DIAG);
  const preEclampsia = inflateFlatUiObj(input, PREFIX_PRE_ECLAMPSIA);
  const maternal = inflateFlatUiObj(input, PREFIX_MATERNAL);
  const ultrasound = inflateFlatUiObj(input, PREFIX_ULTRASOUND);
  const neonatal = inflateFlatUiObj(input, PREFIX_NEONATAL);
  const birthData = inflateFlatUiObj(input, PREFIX_BIRTH);

  if (!!obstetric) baseline.obstetricHistory = obstetric;
  if (!!medical) baseline.medicalHistory = medical;
  if (!!smoking) baseline.smokingHistory = smoking;

  if (!!preEclampsia) clinical.preeclampsiaSymptoms = preEclampsia;
  if (!!maternal) clinical.maternalOutcomes = maternal;
  if (!!ultrasound) clinical.lastUltrasoundData = ultrasound;
  if (!!neonatal) clinical.neonatalOutcomes = neonatal;
  if (!!birthData) clinical.birthData = birthData;

  return removeUndefined({
    id: input.id,
    baselineInformation: baseline,
    clinicalDiagnostics: clinical,
  }) as CrfDataApi
}

const inflateFlatUiObj = (input: any, prefix: string) => {
  const output: any = {};

  Object.keys(input).forEach((key: string) => {
    if (key.startsWith(prefix)) {
      output[key.replace(prefix, '')] = input[key]
    }
  })

  return Object.keys(output).length > 0 ? output : undefined;
}
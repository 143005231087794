import React from 'react';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { Box, Button, Divider, SxProps, Theme, Typography } from '@mui/material';
import {
  AddRounded as AddIcon,
  SwapHorizRounded as TransferIcon,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "../../redux/hooks";
import { selectMidwifeNames, selectRole, selectSiteNames } from '../../redux/slices/account';
import { selectDisplayedImage, selectIsLoadingPatientImages, selectPatientImages, selectSelectedPatient, setDisplayedImage } from '../../redux/slices/patient';
import { AccountRole } from '../../types/account';
import { getFinishReasonUILabel } from '../../types/patient';
import { ImageMetadataUI } from '../../types/image';
import { pregnancyWeekToString, isoDateTimeToDate } from '../../utils/dateTime';
import { getKitIssuedDataProps } from './utils';
import { DAYS, DAY_SIZE, DAY_SPACING, convertImageMetadataToUI } from '../../components/Calendar/utils';
import Section from '../../components/Section';
import Parameter from '../../components/Parameter';
import Calendar from '../../components/Calendar';
import DialogImage from '../../components/dialogs/DialogImage';
import DialogAddSecondID from './DialogAddSecondID';
import DialogTransferMidwife from './DialogTransferMidwife';
import DialogFinishTrial from './DialogFinishTrial';

const DetailsSection = ({
  sx,
}: {
  sx?: SxProps<Theme>,
}) => {
  const dispatch = useDispatch();
  const role = useSelector(selectRole);
  const sites = useSelector(selectSiteNames);
  const midwives = useSelector(selectMidwifeNames);
  const selectedPatient = useSelector(selectSelectedPatient);
  const images = useSelector(selectPatientImages);
  const loadingImages = useSelector(selectIsLoadingPatientImages);
  const displayedImage = useSelector(selectDisplayedImage);

  const [secondaryIDs, setSecondaryIDs] = React.useState<string[]>([]);
  const [openAddID, setOpenAddID] = React.useState(false);
  const [openTransferMidwife, setOpenTransferMidwife] = React.useState(false);
  const [openFinishTrial, setOpenFinishTrial] = React.useState(false);
  const [openImage, setOpenImage] = React.useState(false);
  const [image, setImage] = React.useState<ImageMetadataUI | undefined>(undefined);

  React.useEffect(() => {
    if (selectedPatient) {
      const kits = selectedPatient.testkits.map(kit => kit.testkit);
      kits.splice(0, 1);    // Remove primary ID from list
      setSecondaryIDs(kits);
    }
    else {
      setSecondaryIDs([]);
    }
  }, [selectedPatient])

  React.useEffect(() => {
    if (!!displayedImage) {
      setImage(convertImageMetadataToUI(displayedImage));
      setOpenImage(true);
    }
  }, [displayedImage])

  React.useEffect(() => {
    if (!openImage) {
      setImage(undefined);
      dispatch(setDisplayedImage(undefined));
    }
  }, [dispatch, openImage])

  return (
    <Section sx={{ flexShrink: 0, pr: 0, ...sx }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2, mr: 3, alignItems: 'start' }}>
        <Typography variant='h5'>User Details</Typography>

        {selectedPatient?.id === process.env.REACT_APP_DUMMY_KIT && (
          <Typography variant='h5' sx={theme => ({ color: theme.palette.info.main, ml: 2, alignSelf: 'center' })}>DUMMY USER</Typography>
        )}

        {(role === AccountRole.POWER_MEDIC || role === AccountRole.MEDIC) && (
          <>
            <Button disabled={!selectedPatient} component={Link} to={`/crf/${selectedPatient?.id}`} sx={{ ml: 'auto', mr: 1 }}>View CRF</Button>
            <Button disabled={!selectedPatient || selectedPatient.finished} onClick={() => {setOpenFinishTrial(true)}}>Finish Trial</Button>
            <DialogFinishTrial open={openFinishTrial} userId={selectedPatient?.id} onClose={() => {setOpenFinishTrial(false)}}/>
          </>
        )}
      </Box>
      
      <Box sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1, alignItems: 'stretch', minHeight: 0 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', pr: 2 }}>
          {role === AccountRole.ADMIN ? (
            <Parameter title='Study ID' primaryValue={selectedPatient?.id} secondaryValue={secondaryIDs}/>
          ) : (
            <>
              <Parameter title='Study ID' primaryValue={selectedPatient?.id} secondaryValue={secondaryIDs} buttonIcon={<AddIcon/>} buttonLabel='Issue additional kit to user' buttonDisabled={!selectedPatient} onClick={() => {setOpenAddID(true)}}/>
              <DialogAddSecondID open={openAddID} primaryKit={selectedPatient} onClose={() => {setOpenAddID(false)}}/>
            </>
          )}

          {role !== AccountRole.MEDIC && (
            <Parameter title='Site' primaryValue={selectedPatient?.site && sites[selectedPatient.site]}/>
          )}

          {role === AccountRole.POWER_MEDIC ? (
            <>
              <Parameter title='Midwife' primaryValue={selectedPatient?.midwife && midwives[selectedPatient.midwife]} buttonIcon={<TransferIcon/>} buttonLabel='Transfer user to another midwife' buttonDisabled={!selectedPatient} onClick={() => {setOpenTransferMidwife(true)}}/>
              <DialogTransferMidwife open={openTransferMidwife} primaryKit={selectedPatient} onClose={() => {setOpenTransferMidwife(false)}}/>
            </>
          ) : (
            <Parameter title='Midwife' primaryValue={selectedPatient?.midwife && midwives[selectedPatient.midwife]}/>
          )}

          <Divider sx={{ my: 1.5 }}/>
          
          <Parameter title='Due Date (Ultrasound)' primaryValue={selectedPatient?.dueDate && isoDateTimeToDate(selectedPatient.dueDate)}/>
          <Parameter title='Current Week of Pregnancy' primaryValue={selectedPatient && pregnancyWeekToString(selectedPatient.week, false)}/>

          <Divider sx={{ my: 1.5 }}/>
          
          {/* <Parameter title='Kit Issued' primaryValue={selectedPatient?.startTimestamp && isoDateTimeToDate(selectedPatient.startTimestamp)}/> */}
          <Parameter title='Kit Issued' {...getKitIssuedDataProps(selectedPatient)}/>
          <Parameter title='First Used App' primaryValue={selectedPatient?.appTimestamp && isoDateTimeToDate(selectedPatient.appTimestamp)}/>

          <Divider sx={{ my: 1.5 }}/>
          
          <Parameter title='Finish Date' primaryValue={selectedPatient?.completionTimestamp && isoDateTimeToDate(selectedPatient.completionTimestamp)}/>
          <Parameter title='Reason' primaryValue={getFinishReasonUILabel(selectedPatient?.finalStatus)}/>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: 0 }}>
          <Typography variant='h6'>Submission History</Typography>

          <Box sx={{ display: 'flex', flexDirection: 'row', gap: `${DAY_SPACING}px`, mb: 1 }}>
            {DAYS.map(day => (
              <Typography key={day} variant='caption' sx={theme => ({ width: DAY_SIZE, textAlign: 'center', color: theme.palette.text.secondary })}>{day}</Typography>
            ))}
          </Box>
          
          <Divider sx={{ mr: 4 }}/>
          <Calendar
            loading={loadingImages}
            startDate={selectedPatient && DateTime.fromISO(selectedPatient.startTimestamp)}
            dueDate={selectedPatient && DateTime.fromISO(selectedPatient.dueDate)}
            finishDate={(selectedPatient?.completionTimestamp && DateTime.fromISO(selectedPatient.completionTimestamp)) || undefined}
            images={images}
          />
          <Divider sx={{ mr: 4 }}/>
        </Box>
      </Box>

      {/* <Dialog open={openImage} onClose={() => {setOpenImage(false)}} maxWidth='md'>
        <Image
          src={imageUrl}
          alt='Latest upload'
          maxWidth={Math.min(width - 64, 800)}
          maxHeight={height - 64}
        />
      </Dialog> */}
      <DialogImage image={image} open={openImage} setOpen={setOpenImage}/>
    </Section>
  );
}

export default DetailsSection;
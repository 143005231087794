import { Info } from "luxon";
import { Box, Divider, Typography } from "@mui/material";
import { convertDayJsToLuxon } from "../../utils/dateTime";
import { GroupedImagesMonth } from "./types";
import { DAY_SIZE, DAY_SPACING, TOTAL_WIDTH } from "./utils";
import CalendarDay from "./CalendarDay";

const MONTHS = Info.months();

const CalendarMonth = ({
  hideDivider = false,
  year,
  month,
  today,
  startDay,
  finishDay,
  dueDay,
  formattedImages,
}: {
  hideDivider?: boolean,
  year: number,
  /** 1-indexed, i.e. Jan=1 to Dec=12 */
  month: number,
  /** 1-indexed, i.e. same as a standard calendar */
  today?: number,
  /** -1 for before this month, 32+ for after this month */
  startDay: number,
  /** -1 for before this month, 32+ for after this month */
  finishDay: number,
  /** -1 for before this month, 32+ for after this month */
  dueDay: number,
  formattedImages?: GroupedImagesMonth,
}) => {
  // NOTE: 0th day of MONTH+1 = Last day of MONTH = Num of days in MONTH
  // NOTE: 13th month of YEAR = 1st month of YEAR+1
  const totalDays   = new Date(year, month, 0).getDate();
  const startOffset = convertDayJsToLuxon(new Date(year, month - 1, 1).getDay());
  const lastDay     = Math.min(finishDay, dueDay);

  return (
    <Box id={`${year}-${month}`} sx={{ display: 'flex', flexDirection: 'column', width: TOTAL_WIDTH, mt: 1 }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
        <Typography variant='h6'>{MONTHS[month - 1]}</Typography>
        <Typography variant='h6' sx={{ ml: 'auto' }}>{year}</Typography>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: `${DAY_SPACING}px`, mb: 2 }}>
        {startOffset > 0 && (
          <Box sx={{ width: startOffset * (DAY_SIZE + DAY_SPACING) - DAY_SPACING }}/>
        )}

        {[...Array(totalDays)].map((_, index) => {
          const day = index + 1;

          return (
            <CalendarDay
              key={day}
              day={day}
              active={day >= startDay && day <= lastDay}
              today={day === today}
              startDay={day === startDay}
              finishDay={day === finishDay}
              dueDay={day === dueDay}
              imageData={formattedImages?.[day]}
            />
          )
        })}
      </Box>

      {!hideDivider && (
        <Divider/>
      )}
    </Box>
  );
}

export default CalendarMonth;
import React from 'react';
import { Box, Divider, SxProps, Theme, Typography } from '@mui/material';
import { useDispatch, useSelector } from '../../redux/hooks';
import { selectRole, selectSiteNames } from '../../redux/slices/account';
import { getStockTestkits, selectIsLoadingStockTestkits, selectSelectedStockTestkit, selectStockTestkitFilter, selectStockTestkitSorter, selectStockTestkits, setSelectedStockTestkit } from '../../redux/slices/testkit';
import { AccountRole } from '../../types/account';
import { ADMIN_STATUSES, MIDWIFE_STATUSES, Testkit, TestkitAPIStatus, TestkitUIStatus, getTestkitStatusLabel, getKitStatusUILabel, TestkitUIFilter, getDispatchedFilterOverride, getCompletedFilterOverride } from '../../types/testkit';
import { BLANK, removeUndefined } from '../../utils/global';
import { isoDateTimeToDate } from '../../utils/dateTime';
import { getTestkitLocation } from '../../utils/testkit';
import { Column, RowRendererProps } from '../../components/Table/types';
import Section from '../../components/Section';
import Table from '../../components/Table';
import SearchFilter from '../../components/SearchFilter';
import { Row } from '../../components/Table/renderers';

type FilterValues = {
  id: string,
  status: TestkitUIStatus | null,
  dispatched: boolean,
  incFinished: boolean,
}

const ROW_ID = 120;
const ROW_STATUS = 120;
const ROW_LOCATION = 225;
const ROW_SWAB_EXPIRY = 110;
const ROW_PAPER_EXPIRY = 110;

const BASE_COLUMNS: Column<Testkit>[] = [
  { id: 'id', label: 'Study ID', width: ROW_ID, getValue: (item: Testkit) => item.id },
  { id: 'status', label: 'Status', width: ROW_STATUS, getValue: (item: Testkit) => getTestkitStatusLabel(item) },
  { id: 'location', label: 'Location', width: ROW_LOCATION, getValue: (item: Testkit) => BLANK },
  { id: 'swabExpiry', label: 'Swab Expiry', width: ROW_SWAB_EXPIRY, getValue: (item: Testkit) => item.swabExpiry ? isoDateTimeToDate(item.swabExpiry) : BLANK },
  { id: 'paperExpiry', label: 'Paper Expiry', width: ROW_PAPER_EXPIRY, getValue: (item: Testkit) => item.paperExpiry ? isoDateTimeToDate(item.paperExpiry) : BLANK },
]

const TableSection = ({
  sx,
}: {
  sx?: SxProps<Theme>,
}) => {
  const dispatch = useDispatch();

  const reduxFilter = useSelector(selectStockTestkitFilter);
  const reduxSorter = useSelector(selectStockTestkitSorter);
  const testkits = useSelector(selectStockTestkits);
  const selectedTestkit = useSelector(selectSelectedStockTestkit);
  const isLoadingTestkits = useSelector(selectIsLoadingStockTestkits);

  const role = useSelector(selectRole);
  const sites = useSelector(selectSiteNames);

  const [columns, setColumns] = React.useState<Column<Testkit>[]>([]);

  // De-select User when unloading the User screen
  React.useEffect(() => {
    return () => {
      dispatch(setSelectedStockTestkit(undefined));
    }
  }, [dispatch]);

  // When this screen loads -> fetch stock testkit data based on existing filter/sorting (if any)
  React.useEffect(() => {
    const controller = new AbortController();
    dispatch(getStockTestkits({ signal: controller.signal }));
    return () => { controller.abort() }
  }, [dispatch]);
  
  // Setup columns
  React.useEffect(() => {
    const cols = [...BASE_COLUMNS];
    cols[0].onClick = handleClickColumn;
    cols[1].onClick = handleClickColumn;
    cols[2].getValue = (kit: Testkit) => getTestkitLocation({ kit, role, sites });
    setColumns(cols);
  }, [role, sites, reduxSorter]);

  const getRowColour = ({ item, theme }: { item: Testkit, theme: Theme }) => {
    switch (item.status) {
      case TestkitAPIStatus.NEW:
        return theme.palette.kitStatus.new;
      // case TestkitAPIStatus.SUB_ASSEMBLY:
      //   return theme.palette.kitStatus.subAssembly;
      case TestkitAPIStatus.STOCK:
        return theme.palette.kitStatus.stock;
      case TestkitAPIStatus.ALLOCATED:
        return !!item.dispatched ? theme.palette.kitStatus.dispatched : theme.palette.kitStatus.allocated;
      case TestkitAPIStatus.ISSUED:
        return theme.palette.kitStatus.issued;
      case TestkitAPIStatus.DONE:
        return theme.palette.kitStatus.archived;
      default:
        return undefined;
    }
  }

  const getRowTextColour = ({ item, theme }: { item: Testkit, theme: Theme }) => {
    if (item.id === process.env.REACT_APP_DUMMY_KIT) {
      return theme.palette.info.main;
    }
    else {
      switch (item.status) {
        case TestkitAPIStatus.ISSUED:
          return theme.palette.text.secondary;
        case TestkitAPIStatus.DONE:
          return theme.palette.text.disabled;
        default:
          return undefined;
      }
    }
  }

  const stockRowRenderer = ({ index, item, columns, selected, columnSpacing, ml, mr, style, theme, onClick }: RowRendererProps<Testkit>) => {
    const rowColour  = getRowColour({ item, theme });
    const textColour = getRowTextColour({ item, theme }) || theme.palette.text.primary;
    return (
      <Row
        onClick={onClick && (() => onClick({ index, item }))}
        style={{
          ...style,
          width: undefined,
          borderLeft: rowColour ? `${rowColour} ${ml / 2}px solid` : undefined,
          color: textColour,
          backgroundColor: selected ? theme.palette.action.selected : undefined,
        }}
        sx={{
          paddingLeft: `${ml / 2}px`,
          paddingRight: `${mr}px`,
        }}
      >
        {columns.map((column, colIndex) => {
          const ml = colIndex !== 0 ? `${columnSpacing}px` : undefined;
          const contents = column.getValue?.(item);
  
          return typeof(contents) === 'string' ? (
            <Typography key={column.id} sx={{ width: column.width, ml }}>
              {contents}
            </Typography>
          ) : (
            <Box key={column.id} sx={{ width: column.width, ml, display: 'flex', alignItems: 'center' }}>
              {contents}
            </Box>
          );
        })}
      </Row>
    )
  }
  
  const handleSearch = (values: FilterValues) => {
    dispatch(getStockTestkits({ updateFilter: true, filter: removeUndefined({
      idPattern: values.id || undefined,
      status: values.status || undefined,
      dispatched: role === AccountRole.ADMIN ? values.dispatched : undefined,
      includeCompleted: values.incFinished,
    } as TestkitUIFilter)}));
  }

  const handleClickRow = ({ index }: { index: number }) => {
    dispatch(setSelectedStockTestkit(selectedTestkit !== testkits[index] ? testkits[index] : undefined))
  }

  const handleClickColumn = ({ id }: { id: string }) => {
    // Clicked existing sort param -> change direction
    if (reduxSorter.column === id) {
      dispatch(getStockTestkits({ updateSorter: true, sorter: { column: reduxSorter.column, dir: reduxSorter.dir === 'asc' ? 'desc' : 'asc' }}));
    }
    else {
      dispatch(getStockTestkits({ updateSorter: true, sorter: { column: id as any, dir: 'asc' }}));
    }
  }

  return (
    <Section sx={{ height: '100%', p: 0, ...sx }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'start', overflowX: 'auto', pl: 3, pr: 1, py: 2 }}>
        <SearchFilter
          initialValues={{ id: '', status: null, dispatched: false, incFinished: false } as FilterValues}
          filters={[
            { type: 'text', id: 'id', label: 'Study ID' },
            { type: 'options', id: 'status', label: 'Status', options: role === AccountRole.ADMIN ? ADMIN_STATUSES : MIDWIFE_STATUSES, width: 215,
              getLabel: (status: TestkitUIStatus) => getKitStatusUILabel(status),
            },
            { type: 'boolean', id: 'dispatched', label: 'Dispatched', visible: role === AccountRole.ADMIN, override: getDispatchedFilterOverride(reduxFilter.status) },
            { type: 'boolean', id: 'incFinished', label: 'Finished', override: getCompletedFilterOverride(reduxFilter.status) },
          ]}
          onSearch={handleSearch}
        />
      </Box>

      <Table
        columns={columns}
        items={testkits}
        selectedItemIdx={testkits.findIndex(kit => kit === selectedTestkit)}
        isLoading={isLoadingTestkits}
        noResultsText='No Testkits Found'
        sorter={reduxSorter}
        onClickRow={handleClickRow}
        rowRenderer={stockRowRenderer}
      />

      <Divider/>
      <Box sx={{ px: 3, py: 1 }}>
        <Typography color={theme => theme.palette.text.secondary}>Count: {testkits.length}</Typography>
      </Box>
    </Section>
  );
}

export default TableSection;
import { DateTime } from "luxon";
import validator from "validator";
import { AccountData } from "../types/account";

// TODO: look into using browser 'local storage' instead of cookies
export const COOKIE_EMAIL = 'email';
export const COOKIE_ROLE = 'role';
export const COOKIE_SITE = 'site';
export const COOKIE_ACCESS = 'accessToken';
export const COOKIE_REFRESH = 'refreshToken';

export const BLANK = '-';
export const MIN_DUE_DATE_WEEKS = 0;    // TODO: Will be 25-34 (-> week 6-15)
export const MAX_DUE_DATE_WEEKS = 40;

export const REFRESH_DELAY = 1200;

/** Modifies the input object by removing any properties with a value of undefined */
export const removeUndefined = (obj: any) => {
  Object.keys(obj).forEach(key => obj[key] === undefined && delete obj[key])
  return obj;
}

export const validateNotEmpty = (param?: string) => !param ? 'Required' : undefined;

export const validateEmail = (email: string) => {
  const notEmpty = validateNotEmpty(email);
  if (!!notEmpty) return notEmpty;
  
  return !validator.isEmail(email) ? 'Invalid email address' : undefined;
}

export const validateDate = (date: string, format = 'dd/MM/yyyy') => {
  const notEmpty = validateNotEmpty(date);
  if (!!notEmpty) return notEmpty;
  
  return !DateTime.fromFormat(date, format).isValid ? 'Invalid date' : undefined;
}

export const validateInt = (input: string | null, min: number, max: number) => {
  if (input === null) return 'Required';

  const value = Number.parseInt(input);
  if (Number.isNaN(value)) return 'Invalid value';

  return (value < min || value > max) ? `Must be between ${min} and ${max}` : undefined;
}

export const validateDueDate = (date: DateTime<boolean> | null) => {
  if (date === null) return 'Required';
  if (!date.isValid) return 'Invalid date';

  const dtMin = DateTime.now().plus({ weeks: MIN_DUE_DATE_WEEKS });
  const dtMax = DateTime.now().plus({ weeks: MAX_DUE_DATE_WEEKS });

  return (date < dtMin || dtMax < date) ? `Must be between ${dtMin.toLocaleString(DateTime.DATE_SHORT)} and ${dtMax.toLocaleString(DateTime.DATE_SHORT)}` : undefined;
}

export const getFullName = (firstName: string, lastName: string) => `${firstName} ${lastName}`;
export const getAccountFullName = (account: AccountData) => `${account.firstName} ${account.lastName}`;

import { Autocomplete, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "../../redux/hooks";
import { selectMidwives, selectSites } from "../../redux/slices/account";
import { transferToMidwife } from "../../redux/slices/patient";
import { getAccountFullName, removeUndefined, validateNotEmpty } from "../../utils/global";
import { DialogCloseHandler } from "../../types/dialog";
import { Patient } from "../../types/patient";
import { AccountData, AccountRole, SiteData } from "../../types/account";
import BaseFormDialog from "../../components/dialogs/BaseFormDialog";

type TransferMidwifeData = {
  site: SiteData | null,
  midwife: AccountData | null,
}

const DialogTransferMidwife = ({
  open,
  primaryKit,
  onClose,
}: {
  open: boolean,
  primaryKit?: Patient,
  onClose?: DialogCloseHandler,
}) => {
  const dispatch = useDispatch();
  const sites = useSelector(selectSites);
  const midwives = useSelector(selectMidwives);

  const filterMidwives = (site: SiteData | null) => {
    if (!site) {
      return [];
    }
    else {
      return midwives.filter(midwife => (midwife.role === AccountRole.POWER_MEDIC) || (midwife.site === site.id))
    }
  }

  const handleValidate = ({ site, midwife }: TransferMidwifeData) => removeUndefined({
    site: validateNotEmpty(site?.id),
    midwife: validateNotEmpty(midwife?.username),
  })

  const handleSubmit = ({ site, midwife }: TransferMidwifeData) => {
    if (!primaryKit || !site || !midwife) return;

    dispatch(transferToMidwife({ patient: primaryKit, midwife: midwife, siteId: site.id }))
    .then(() => { onClose?.({}, 'closeClick') })
    .catch(() => {})
  }

  return (
    <BaseFormDialog
      title={`Transfer user ${primaryKit?.id} to a different midwife`}
      open={open}
      onClose={onClose}
      initialValues={{ site: sites.find(site => site.id === primaryKit?.site) || null, midwife: null } as TransferMidwifeData}
      validate={handleValidate}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        handleBlur,
        setFieldValue,
      }) => (
        <>
          <Autocomplete
            disablePortal
            value={values.site}
            options={sites}
            getOptionLabel={site => site.name}
            onChange={(e, val) => { setFieldValue('site', val) }}
            onBlur={handleBlur}
            isOptionEqualToValue={(option: any, value) => value.id === option.id}
            renderInput={params => <TextField
              {...params}
              required
              name='site'
              label='Site'
              error={!!errors.site}
              helperText={errors.site}
            />}
          />

          <Autocomplete
            disablePortal
            disabled={!values.site}
            value={values.midwife}
            options={filterMidwives(values.site)}
            getOptionLabel={midwife => getAccountFullName(midwife)}
            onChange={(e, val) => { setFieldValue('midwife', val) }}
            onBlur={handleBlur}
            isOptionEqualToValue={(option: any, value) => value.username === option.username}
            sx={{ mt: 2 }}
            renderInput={params => <TextField
              {...params}
              required
              name='midwife'
              label='Midwife'
              error={!!errors.midwife}
              helperText={errors.midwife}
            />}
          />

          {values.site && (values.site.id !== primaryKit?.site) && (
            <Typography variant='caption' sx={theme => ({ color: theme.palette.secondary.main, mt: 2 })}>Note: this will transfer the user to a different site</Typography>
          )}
        </>
      )}
    </BaseFormDialog>
  );
}

export default DialogTransferMidwife;
import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { removeUndefined, validateNotEmpty } from "../../utils/global";
import { useDispatch, useSelector } from "../../redux/hooks";
import { getAvailableTestkits, selectAvailableTestkits } from "../../redux/slices/testkit";
import { addSecondaryID } from "../../redux/slices/patient";
import { DialogCloseHandler } from "../../types/dialog";
import { Testkit } from "../../types/testkit";
import BaseFormDialog from "../../components/dialogs/BaseFormDialog";
import { Patient } from "../../types/patient";
import { selectRole, selectSiteNames } from "../../redux/slices/account";
import { AccountRole } from "../../types/account";

type AddIdData = {
  kit: Testkit | null,
}

const DialogAddSecondID = ({
  open,
  primaryKit,
  onClose,
}: {
  open: boolean,
  primaryKit?: Patient,
  onClose?: DialogCloseHandler,
}) => {
  const dispatch = useDispatch();
  const role = useSelector(selectRole);
  const sites = useSelector(selectSiteNames);
  const testkits = useSelector(selectAvailableTestkits);

  React.useEffect(() => {
    if (open) {
      const controller = new AbortController();    
      dispatch(getAvailableTestkits({ siteId: primaryKit?.site, signal: controller.signal }));
      return () => { controller.abort() }
    }
  }, [open]);

  const handleValidateAddSecondaryID = ({ kit }: AddIdData) => removeUndefined({
    kit: validateNotEmpty(kit?.id),
  })

  const handleSubmitAddSecondaryID = ({ kit }: AddIdData) => {
    if (!kit || !primaryKit) return;

    dispatch(addSecondaryID({ primaryId: primaryKit.id, secondaryId: kit.id }))
    .then(() => { onClose?.({}, 'closeClick') })
    .catch(() => {})
  }

  return (
    <BaseFormDialog
      title={`Issue additional testkit to user ${primaryKit?.id}`}
      open={open}
      onClose={onClose}
      initialValues={{ kit: null } as AddIdData}
      validate={handleValidateAddSecondaryID}
      onSubmit={handleSubmitAddSecondaryID}
    >
      {({
        values,
        errors,
        handleBlur,
        setFieldValue,
      }) => (
        <>
          {role !== AccountRole.MEDIC && (
            <Autocomplete
              disabled
              disablePortal
              value={primaryKit?.site && sites[primaryKit.site]}
              options={[]}
              renderInput={params => <TextField {...params} label='Site'/>}
              sx={{ mb: 2 }}
            />
          )}

          <Autocomplete
            disablePortal
            value={values.kit}
            options={testkits}
            getOptionLabel={kit => kit.id}
            onChange={(e, val) => { setFieldValue('kit', val) }}
            onBlur={handleBlur}
            isOptionEqualToValue={(option: any, value) => value.id === option.id}
            renderInput={params => <TextField
              {...params}
              required
              name='kit'
              label='ID'
              error={!!errors.kit}
              helperText={errors.kit}
            />}
          />
        </>
      )}
    </BaseFormDialog>
  );
}

export default DialogAddSecondID;
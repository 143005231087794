import { TableSortConfig } from "../components/Table/types"
import { BLANK } from "../utils/global"

export type Patient = {
  objectType: 'patient',
  id: string,
  site: string,
  midwife: string,
  testkits: PatientTestkit[],

  dueDate: string,
  week: number,
  finished: boolean,
  finalStatus?: FinishTrialReason,
  completionTimestamp?: string,

  startTimestamp: string,
  appTimestamp?: string,
  lastImageTimestamp?: string,
  lastSuccessfulProcessingTimestamp?: string,
}

export type PatientTestkit = {
  testkit: string,
  issueTimestamp: string,
}

export type PatientFilter = {
  idPattern?: string,
  siteId?: string,
  midwife?: string,
  includeCompleted?: boolean,
}

export type PatientSorter = {
  dueDateSort?: boolean,
  descending?: boolean,
}

export type PatientUISorter = TableSortConfig & {
  column: 'id' | 'dueDate',
  dir: 'asc' | 'desc',
}

export enum FinishTrialReason {
  Birth       = 'BIRTH',
  Miscarriage = 'MISCARRIAGE',
  Left        = 'LEFT',
}

export const convertSorterToAPI = (sorter?: PatientUISorter) => {
  const apiSorter: PatientSorter = { descending: !!sorter && sorter.dir === 'desc' }

  if (sorter?.column === 'dueDate') {
    apiSorter.dueDateSort = true;
  }

  return apiSorter;
}

export const getFinishReasonUILabel = (reason?: FinishTrialReason) => {
  switch (reason) {
    case FinishTrialReason.Birth:
      return 'Gave birth';
    case FinishTrialReason.Miscarriage:
      return 'Miscarriage';
    case FinishTrialReason.Left:
      return 'Left the trial';
    default:
      return BLANK;
  }
}

import { AccountData } from "../types/account"
import { getAccountFullName } from "./global";

export const sortMidwives = (accounts: AccountData[]) => {
  accounts.sort((a, b) => a.lastName.localeCompare(b.lastName) || a.firstName.localeCompare(b.firstName))
}

export const getMidwfeNames = (accounts: AccountData[]) => {
  const names: { [key: string]: string } = {};
  accounts.forEach(midwife => { names[midwife.username] = getAccountFullName(midwife) });
  return names;
}
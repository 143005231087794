import { DialogCloseHandler } from "../../types/dialog";
import BaseFormDialog from "../../components/dialogs/BaseFormDialog";
import { validatePassword } from "../../utils/auth";
import { removeUndefined } from "../../utils/global";
import FormTextInput from "../../components/form/FormTextInput";
import { AccountData, ChangePasswordData } from "../../types/account";
import { useDispatch } from "../../redux/hooks";
import { changePassword } from "../../redux/slices/account";
import { ApiErrorResponse, ApiResponse } from "../../api/types/base";

const DialogChangePassword = ({
  open,
  onClose,
}: {
  open: boolean,
  onClose?: DialogCloseHandler,
}) => {
  const dispatch = useDispatch();

  const handleValidateAddUser = ({ currentPassword, newPassword }: ChangePasswordData) => removeUndefined({
    currentPassword: validatePassword(currentPassword, false),
    newPassword: validatePassword(newPassword, true),
  })

  const handleSubmitAddUser = ({ currentPassword, newPassword }: ChangePasswordData) => {
    dispatch(changePassword({ currentPassword, newPassword }))
    .then(response => {
      const payload = response.payload as ApiResponse<AccountData> | ApiErrorResponse;

      if (payload.type === 'successResponse') {
        onClose?.({}, 'closeClick')
      }
    })
  }

  return (
    <BaseFormDialog
      title='Change account password'
      open={open}
      onClose={onClose}
      initialValues={{ currentPassword: '', newPassword: '' } as ChangePasswordData}
      validate={handleValidateAddUser}
      onSubmit={handleSubmitAddUser}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
      }) => (
        <>
          <FormTextInput
            required
            label='Current Password'
            name='currentPassword'
            type='password'
            autoComplete='current-password'
            values={values}
            errors={errors}
            onChange={handleChange}
            onBlur={handleBlur}
            sx={{ mb: 2 }}
          />
          <FormTextInput
            required
            label='New Password'
            name='newPassword'
            type='password'
            autoComplete='new-password'
            values={values}
            errors={errors}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </>
      )}
    </BaseFormDialog>
  );
}

export default DialogChangePassword;
import React from "react";
import { Screen, setScreenTitle } from "../../routes/screens";
import ScreenBox from "../../components/ScreenBox";
import FAQSection from "./FAQSection";

const FaqScreen = () => {
  React.useEffect(() => {
    setScreenTitle(Screen.FAQ);
  }, []);
  
  return (
    <ScreenBox>
      <FAQSection/>
    </ScreenBox>
  );
}

export default FaqScreen;
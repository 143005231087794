import { Box, Typography } from "@mui/material";
import { toast, ToastOptions } from "react-toastify";

export type ToastMessage = string | string[]
export type ToastText = {
  title: string,
  message?: ToastMessage,
}

const ToastContent = ({
  title,
  message,
}: ToastText) => {
  const getMessage = (text: string, index?: number) => (
    <Typography key={index} variant='caption' sx={{ opacity: 0.84 }}>{text}</Typography>
  )

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography variant='body1'>{title}</Typography>

      {message && (
        Array.isArray(message) ? message.map((line, idx) => getMessage(line, idx)) : getMessage(message)
      )}
    </Box>
  );
}

export const Toast = {
  info:    (text: ToastText, options?: ToastOptions) => toast.info(<ToastContent {...text}/>, options),
  success: (text: ToastText, options?: ToastOptions) => toast.success(<ToastContent {...text}/>, options),
  warn:    (text: ToastText, options?: ToastOptions) => toast.warn(<ToastContent {...text}/>, options),
  error:   (text: ToastText, options?: ToastOptions) => toast.error(<ToastContent {...text}/>, options),
}
import { Paper, SxProps, Theme } from "@mui/material"

const Section = ({
  children,
  sx,
}: {
  children?: React.ReactNode,
  sx?: SxProps<Theme>,
}) => {
  return (
    <Paper sx={{
      display: 'flex', flexDirection: 'column',
      boxSizing: 'border-box',
      overflow: 'hidden',
      bgcolor: '#FFFFFFCC',
      // borderRadius: 6,
      px: 3, py: 2,
      ...sx,
    }}>
      {children}
    </Paper>
  )
}

export default Section;
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Box, ButtonBase, Divider, Typography, styled } from '@mui/material';
import { Logout as LogoutIcon } from '@mui/icons-material';
import { useDispatch, useSelector } from '../redux/hooks';
import { getAccountData, getAllMidwives, getAllSites, logout, selectRole } from '../redux/slices/account';
import { AccountRole } from '../types/account';
import { ADMIN_SCREENS, MEDIC_SCREENS, POWER_MEDIC_SCREENS } from '../routes/screens';
import { ReactComponent as Logo } from '../assets/Logo.svg';

const SIZE = 64;

const SCREENS = {
  [AccountRole.ADMIN]: ADMIN_SCREENS,
  [AccountRole.POWER_MEDIC]: POWER_MEDIC_SCREENS,
  [AccountRole.MEDIC]: MEDIC_SCREENS,
}

const NavButton = styled(NavLink)(({ theme }) => ({
  display: 'flex', flexDirection: 'column',
  justifyContent: 'center', alignItems: 'center',
  width: `${SIZE}px`, height: `${SIZE}px`,
  borderRadius: 12,
  marginTop: theme.spacing(1),
  textDecoration: 'none',
  color: 'inherit',
  '&:hover': {
    backgroundColor: theme.palette.actionContrast.hover,
  },
  '&.active': {
    backgroundColor: theme.palette.actionContrast.selected,
  },
}))

const NavMenu = ({
  children,
}: {
  children?: React.ReactNode,
}) => {
  const dispatch = useDispatch();
  const role = useSelector(selectRole);

  React.useEffect(() => {
    const controller = new AbortController();

    dispatch(getAccountData({ signal: controller.signal }))
    .then(() => {
      dispatch(getAllSites({ signal: controller.signal }))
      dispatch(getAllMidwives({ signal: controller.signal }))
    })

    return () => { controller.abort() }
  }, [dispatch])

  const handleClickLogout = () => {
    dispatch(logout());
  }

  return (
    <Box sx={{
      width: '100vw', height: '100vh',
      display: 'flex', flexDirection: 'row',
      alignItems: 'stretch',
    }}>
      <Box sx={theme => ({
        display: 'flex', flexDirection: 'column',
        alignItems: 'center',
        px: 0.5, py: 1,
        bgcolor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        borderRadius: 0,
      })}>
        <Logo width={52} height={52}/>
        <Divider flexItem sx={theme => ({ mt: 1, mx: 0.5, borderColor: theme.palette.actionContrast.focus })}/>

        {(role ? SCREENS[role] : []).map((screen) => (
          <NavButton key={screen.id} to={screen.href}>
            {screen.icon}
            <Typography variant='caption'>{screen.title}</Typography>
          </NavButton>
        ))}

        <ButtonBase
          onClick={handleClickLogout}
          sx={theme => ({
            display: 'flex', flexDirection: 'column',
            justifyContent: 'center', alignItems: 'center',
            width: '60px', height: '60px',
            borderRadius: 3,
            mt: 'auto',
            '&:hover': {
              bgcolor: theme.palette.actionContrast.hover,
            },
          })}
        >
          <LogoutIcon fontSize='large'/>
          <Typography variant='subtitle2'>Logout</Typography>
        </ButtonBase>
      </Box>

      {children}
    </Box>
  );
}

export default NavMenu;

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { Slide, ToastContainer } from 'react-toastify';
import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

import './index.css';
import reportWebVitals from './reportWebVitals';
import { theme } from './theme';
import { store } from './redux/store';
import { injectStore } from './api/endpoints/auth';
import Router from './routes/Router';

import 'react-toastify/dist/ReactToastify.min.css'

injectStore(store);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

/**
 * NOTE:
 * In dev mode ONLY, React.Strict mode causes components to render twice. This helps test
 * resiliance of components but means you must be careful with performing actions when a
 * component mounts/unmounts (e.g. to prevent duplicate API calls)
 */

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <ToastContainer
        limit={3}
        autoClose={5000}
        position='top-right'
        newestOnTop={false}
        // closeOnClick
        pauseOnHover
        pauseOnFocusLoss={false}
        transition={Slide}
      />

      <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='en-gb'>
        <ReduxProvider store={store}>
          <Router/>
        </ReduxProvider>
      </LocalizationProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import validator from 'validator';
import { LoginData, RegistrationData } from "../types/auth";
import { removeUndefined, validateEmail, validateNotEmpty } from './global';

const POWER_ADMINS = [
  'matt.ray@morgan-iat.co.uk',
  'james.quest@morgan-iat.co.uk',
  'pawel@deck42.co.uk',
]

const passwordRequirements: validator.StrongPasswordOptions & { returnScore?: false | undefined } = {
  minLength: 8,
  minLowercase: 1,
  minUppercase: 1,
  minNumbers: 1,
  minSymbols: 0,
  returnScore: false,
};

export const validatePassword = (password: string, isNewPW: boolean) => {
  const notEmpty = validateNotEmpty(password);
  if (!!notEmpty) return notEmpty;

  return (isNewPW && !validator.isStrongPassword(password, passwordRequirements)) ?
    'Password must be at least 8 characters, with at least 1 uppercase letter, 1 lowercase letter, and 1 number' : undefined;
}
    
export const validateLogin = ({ email, password }: LoginData) => (removeUndefined({
  email: validateEmail(email),
  password: validatePassword(password, false),
}))

export const validateRegistration = ({ email, password, firstName, lastName, activationCode }: RegistrationData) => (removeUndefined({
  email: validateEmail(email),
  password: validatePassword(password, false),
  firstName: validateNotEmpty(firstName),
  lastName: validateNotEmpty(lastName),
  activationCode: validateNotEmpty(activationCode),
}))

export const isPowerAdmin = (email: string) => {
  return POWER_ADMINS.indexOf(email) >= 0;
}
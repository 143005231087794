import { Box, Button, SxProps, Theme, Typography } from '@mui/material';
import AccountAPI from '../../api/endpoints/account';
import { useDispatch, useSelector } from '../../redux/hooks';
import { selectRole, selectSelectedMidwife, selectSiteNames } from '../../redux/slices/account';
import { getAccountFullName } from '../../utils/global';
import { AccountRole } from '../../types/account';
import Section from '../../components/Section';
import Parameter from '../../components/Parameter';

const TITLE_WIDTH = 90;
const VALUE_WIDTH = 300;

const DetailsSection = ({
  sx,
}: {
  sx?: SxProps<Theme>,
}) => {
  // const dispatch = useDispatch();
  const role = useSelector(selectRole);
  const selectedMidwife = useSelector(selectSelectedMidwife);
  const sites = useSelector(selectSiteNames);

  // const handleClickDebug = () => {
  
  // }

  return (
    <Section sx={{ alignSelf: 'stretch', ...sx }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'start', mb: 2 }}>
        <Typography variant='h5'>Midwife Details</Typography>

        {/* {role === AccountRole.ADMIN && (
          <Button variant='text' onClick={handleClickDebug} sx={{ ml: 'auto' }}>Debug</Button>
        )} */}
      </Box>

      <Parameter title='Name' primaryValue={selectedMidwife && getAccountFullName(selectedMidwife)} titleWidth={TITLE_WIDTH} valueWidth={VALUE_WIDTH}/>
      <Parameter title='Email' primaryValue={selectedMidwife?.username} titleWidth={TITLE_WIDTH} valueWidth={VALUE_WIDTH}/>
      {role !== AccountRole.MEDIC && (
        <Parameter title='Site' primaryValue={selectedMidwife?.site && sites[selectedMidwife?.site]} titleWidth={TITLE_WIDTH} valueWidth={VALUE_WIDTH}/>
      )}

      {/* <Typography sx={{ mt: 2 }}>Users</Typography>
      <Parameter title='Active' primaryValue={undefined} titleWidth={TITLE_WIDTH}/>
      <Parameter title='Inactive' primaryValue={undefined} titleWidth={TITLE_WIDTH}/>
      <Parameter title='Archived' primaryValue={undefined} titleWidth={TITLE_WIDTH}/> */}
    </Section>
  );
}

export default DetailsSection;
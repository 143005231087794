import { createTheme, TypeAction } from "@mui/material/styles";
import type {} from '@mui/x-date-pickers/themeAugmentation';

// const BASE_SPACING = 8;
const BASE_RADIUS = 3;

const themeParams = {
  palette: {
    primary: { main: '#CB218E' },
    secondary: { main: '#935ED5' },
    text: {
      primary: '#280951',
      secondary: '#28095199',
      disabled: '#2B203B61',
    },
    info: { main: '#3498DB' },
    success: { main: '#07BC0C' },
    warning: { main: '#F1C40F' },
    error: { main: '#E74C3C' },
    background: {
      angle: -15,
      top: '#F2F7FD',
      bottom: '#FBEDF6',
    },
    action: {
      // hover: 'rgba(147, 94, 213, 0.04)',
      // selected: 'rgba(147, 94, 213, 0.08)',
      // focus: 'rgba(147, 94, 213, 0.12)',
    },
    actionContrast: {
      active: '#FFF',
      hover: 'rgba(255, 255, 255, 0.12)',
      hoverOpacity: 0.12,
      selected: 'rgba(255, 255, 255, 0.20)',
      selectedOpacity: 0.20,
      focus: 'rgba(255, 255, 255, 0.28)',
      focusOpacity: 0.28,
      disabled: 'rgba(255, 255, 255, 0.26)',
      disabledBackground: 'rgba(255, 255, 255, 0.12)',
      disabledOpacity: 0.38,
      activatedOpacity: 0.24,
    },
    kitStatus: {
      new: '#C9F7BA',
      subAssembly: '#F7C4BA',
      stock: '#F7F7BA',
      allocated: '#DEC4F8',
      dispatched: '#F8DFF8',
      stockSite: '#FFFFFF',
      issued: '#C8D8F9',
      archived: '#E0E0E0',
    },
  },
  typography: {
    fontFamily: '"Poppins", "Roboto", "Helvetica", "Arial", sans-serif',
    h4: { lineHeight: 1.1765 },
    h5: { lineHeight: 1.667 },      // 1.5 -> 36px, 1.667 -> 40px
    h6: { fontWeight: 400 },
    caption: { lineHeight: 1.667 },
  },
}

export const theme = createTheme({
  ...themeParams,
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        contained: {
          fontSize: '1.125rem',
          lineHeight: 1.333,
          borderRadius: '18px',
        },
        text: {
          fontSize: '1rem',
          lineHeight: 1.5,
          borderRadius: '18px',
          padding: '6px 12px',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: '8px',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: '8px',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: BASE_RADIUS * 4,
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&:focus': {
            backgroundColor: 'transparent',
            '&.Mui-selected': {
              backgroundColor: themeParams.palette.primary.main,
            },
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: BASE_RADIUS * 6,
          // padding: `${BASE_SPACING * 2}px ${BASE_SPACING * 3}px`,
        },
      },
    },
  },
});

type TypeKitStatus = {
  new: string,
  subAssembly: string,
  stock: string,
  allocated: string,
  dispatched: string,
  stockSite: string,
  issued: string,
  archived: string,
}

declare module '@mui/material/styles' {
  interface Palette {
    actionContrast: TypeAction,
    kitStatus: TypeKitStatus,
  }

  interface PaletteOptions {
    actionContrast?: Partial<TypeAction>,
    kitStatus?: Partial<TypeKitStatus>,
  }

  interface TypeBackground {
    angle: number,
    top: string,
    bottom: string,
  }
}
import { Box, ButtonBase, Typography } from "@mui/material";

const StockButton = ({
  title,
  hint,
  description,
  icon,
  onClick,
  // sx,
}: {
  title: string,
  hint?: string,
  description: string,
  icon: React.ReactNode,
  onClick?: () => void,
  // sx?: SxProps,
}) => {
  return (
    <ButtonBase onClick={onClick} sx={theme => ({
      display: 'flex', flexDirection: 'row', flexShrink: 0,
      justifyContent: 'start', alignItems: 'center',
      px: 2, py: 1,
      borderRadius: 3,
      textAlign: 'left',
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
      // ...sx,
    })}>
      {icon}

      <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Typography variant='h6' color='primary'>{title}</Typography>
          {hint && (
            <Typography variant='caption' color={theme => theme.palette.text.disabled} sx={{ ml: 3 }}>{hint}</Typography>
          )}
        </Box>

        <Typography variant='body1'>{description}</Typography>
      </Box>
    </ButtonBase>
  );
}

export default StockButton;
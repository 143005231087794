import { DateTime } from "luxon"
import { Patient } from "../../types/patient";
import { dateToString, DUE_DATE_WEEK } from "../../utils/dateTime";

export const getKitIssuedDataProps = (patient?: Patient) => {
  if (!patient?.startTimestamp || !patient?.dueDate) {
    return { primaryValue: undefined };
  }

  const startDate = DateTime.fromISO(patient.startTimestamp);
  const dueDate   = DateTime.fromISO(patient.dueDate);

  const week = Math.max(0, Math.floor(DUE_DATE_WEEK - dueDate.diff(startDate, 'weeks').weeks));

  return {
    primaryValue: dateToString(startDate),
    secondaryValue: `Week ${week}`,
  }
}
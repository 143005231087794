import { Box, ButtonBase, Tooltip, Typography } from "@mui/material";
import { Error as InvalidIcon } from '@mui/icons-material';
import { getImageStatusUILabel, ImageMetadata, ImageType, isImageValid } from "../../types/image";
import Image from "../../components/Image";

const ImagePreview = ({
  image,
  imageType,
  onClick,
}: {
  image: ImageMetadata,
  imageType: ImageType,
  onClick?: (image: ImageMetadata) => void,
}) => {
  const patientId = image.patient?.replace('P-', '');

  const getImageType = (image: ImageMetadata, type: ImageType) => {
    if (type === 'whiteBalance' && image.whitebalanceFileUrl) {
      return image.whitebalanceFileUrl;
    }
    else if (type === 'mask' && image.maskFileUrl) {
      return image.maskFileUrl;
    }
    else {
      return image.rawFileUrl;
    }
  }

  // if (item.id === process.env.REACT_APP_DUMMY_KIT) {
  //   return theme.palette.info.main;
  // }
  // else if (item.finished) {
  //   return theme.palette.text.disabled;
  // }

  return (
    <ButtonBase
      sx={theme => ({ borderRadius: 3, overflow: 'hidden', backgroundColor: theme.palette.background.paper, boxShadow: theme.shadows[1] })}
      onClick={onClick && (() => onClick(image))}
    >
      <Image
        src={getImageType(image, imageType)}
        alt={`${patientId} | ${image.imageTimestamp}`}
        maxWidth={300}
        maxHeight={300}
        forceOrientation='vertical'
      />

      <Box sx={{
        position: 'absolute', top: 0,
        width: '100%',
        backgroundColor: '#fffd',
      }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: 24, m: 0.5, ml: 1 }}>
          <Typography variant='body2' color={patientId === process.env.REACT_APP_DUMMY_KIT ? (theme => theme.palette.info.main) : undefined}>{patientId}</Typography>

          {!isImageValid(image) && (
            <Tooltip disableInteractive title={getImageStatusUILabel(image.status)} sx={{ ml: 'auto' }}>
              <InvalidIcon color='error'/>
            </Tooltip>
          )}
        </Box>
      </Box>
    </ButtonBase>
  );
}

export default ImagePreview;
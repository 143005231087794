import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import Cookies from 'universal-cookie';
import type { RootState } from "../store";
import AccountAPI from "../../api/endpoints/account";
import { LoginData, RegistrationData } from "../../types/auth";
import { AccountData, AccountRole, ChangeAccountData, SiteData } from "../../types/account";
import { ApiErrorResponse, EndpointRequest } from "../../api/types/base";
import { COOKIE_ACCESS, COOKIE_EMAIL, COOKIE_REFRESH, COOKIE_ROLE, COOKIE_SITE, getFullName } from "../../utils/global";
import { showErrorMessage } from "../utils";
import { getMidwfeNames, sortMidwives } from "../../utils/midwives";
import { Toast } from "../../components/Toast";
import { getSiteNames } from "../../utils/sites";

interface AccountState {
  accessToken: string,
  refreshToken: string,
  isTokenRefreshing: boolean,

  isLoggingIn: boolean,
  isRegistering: boolean,
  email: string,
  firstName: string,
  lastName: string,
  role?: AccountRole,
  site?: string,

  isLoadingMidwives: boolean,
  midwives: AccountData[],
  midwifeNames: {[key: string]: string},
  selectedMidwife?: AccountData,

  sites: SiteData[],
  siteNames: {[key: string]: string},
}

const cookies = new Cookies(undefined, { path: '/', sameSite: 'lax' });

const initialState: AccountState = {
  accessToken: cookies.get(COOKIE_ACCESS) || '',
  refreshToken: cookies.get(COOKIE_REFRESH) || '',
  isTokenRefreshing: false,

  isLoggingIn: false,
  isRegistering: false,
  email: cookies.get(COOKIE_EMAIL) || '',
  firstName: '',
  lastName: '',
  role: cookies.get(COOKIE_ROLE) || undefined,
  site: cookies.get(COOKIE_SITE) || undefined,

  isLoadingMidwives: false,
  midwives: [],
  midwifeNames: {},
  selectedMidwife: undefined,

  sites: [],
  siteNames: {},
}

// NOTE: 'state' is only mutable inside 'createSlice'
export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    logout: (state) => {
      state.email = '';
      state.accessToken = '';
      state.refreshToken = '';

      cookies.remove(COOKIE_EMAIL);
      cookies.remove(COOKIE_ACCESS);
      cookies.remove(COOKIE_REFRESH);
      cookies.remove(COOKIE_ROLE);
      cookies.remove(COOKIE_SITE);
    },
    updateTokens: (state, action: PayloadAction<any>) => {
      if (action?.payload) {
        state.accessToken = action.payload.accessToken;
        state.refreshToken = action.payload.refreshToken;

        cookies.set(COOKIE_ACCESS, state.accessToken);
        cookies.set(COOKIE_REFRESH, state.refreshToken);
      }
    },
    setSelectedMidwife: (state, action: PayloadAction<AccountData | undefined>) => {
      state.selectedMidwife = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state, action) => {
      state.isLoggingIn = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.isLoggingIn = false;

      state.email = action.meta.arg.email;
      state.accessToken = action.payload.data.accessToken;
      state.refreshToken = action.payload.data.refreshToken;

      cookies.set(COOKIE_EMAIL, state.email);
      cookies.set(COOKIE_ACCESS, state.accessToken);
      cookies.set(COOKIE_REFRESH, state.refreshToken);
    });
    builder.addCase(login.rejected, (state, action) => {
      state.isLoggingIn = false;

      state.email = '';
      state.accessToken = '';
      state.refreshToken = '';

      cookies.remove(COOKIE_EMAIL);
      cookies.remove(COOKIE_ACCESS);
      cookies.remove(COOKIE_REFRESH);

      showErrorMessage(action.payload as ApiErrorResponse, (error) => ({
        title: 'Login failed',
        message: error.code?.description,
      }))
    });

    builder.addCase(registerNewAccount.fulfilled, (state, action) => {
      Toast.success({
        title: 'Registration successful!',
        message: 'You\'ll receive a confirmation email to complete the process',
      });
    });
    builder.addCase(registerNewAccount.rejected, (state, action) => {  
      // TODO: change message depending on error
      if (!action.meta.aborted && !action.meta.arg.signal?.aborted) {
        Toast.error({ title: 'Failed to register new user' });
      }
    });

    builder.addCase(forgotPassword.fulfilled, () => {
      Toast.success({
        title: 'Success!',
        message: 'You\'ll receive an email with your new password. This can be changed again after logging in',
      });
    });
    builder.addCase(forgotPassword.rejected, (state, action) => {  
      // TODO: change message depending on error
      if (!action.meta.aborted && !action.meta.arg.signal?.aborted) {
        Toast.error({ title: 'Failed to send recovery email' });
      }
    });

    builder.addCase(changePassword.fulfilled, () => {
      Toast.success({ title: 'Successfully updated password' });
    });
    builder.addCase(changePassword.rejected, (state, action) => {  
      if (!action.meta.aborted && !action.meta.arg.signal?.aborted) {
        Toast.error({ title: 'Failed to update password' });
      }
    });

    builder.addCase(getAccountData.fulfilled, (state, action) => {
      state.firstName = action.payload.data.firstName;
      state.lastName = action.payload.data.lastName;
      state.role = action.payload.data.role;
      state.site = action.payload.data.site;

      cookies.set(COOKIE_ROLE, state.role);
      cookies.set(COOKIE_SITE, state.site);
    });
    builder.addCase(getAccountData.rejected, (state, action) => {
      if (!action.meta.aborted && !action.meta.arg.signal?.aborted) {
        Toast.error({ title: 'Failed to retrieve account data' });
      }
    });

    builder.addCase(updateAccountData.fulfilled, (state, action) => {
      if (!!action.meta.arg.firstName) {
        state.firstName = action.meta.arg.firstName;
      }

      if (!!action.meta.arg.lastName) {
        state.lastName = action.meta.arg.lastName;
      }

      // If logged-in user is a midwife, update relevant midwife info too
      const accounts = [...state.midwives];
      const i = accounts.findIndex(account => account.username === state.email);

      if (i >= 0) {
        accounts[i] = { ...action.payload.data };
        sortMidwives(accounts);

        state.midwives = accounts;
        state.midwifeNames = getMidwfeNames(accounts);
      }
    });
    builder.addCase(updateAccountData.rejected, (state, action) => {
      if (!action.meta.aborted && !action.meta.arg.signal?.aborted) {
        Toast.error({ title: 'Failed to update account data' });
      }
    });

    builder.addCase(getAllMidwives.pending, (state, action) => { state.isLoadingMidwives = true; });
    builder.addCase(getAllMidwives.fulfilled, (state, action) => {
      state.isLoadingMidwives = false;
      state.selectedMidwife = undefined;
      
      if (action.payload) {
        const accounts = action.payload.data;
        sortMidwives(accounts);
        
        state.midwives = accounts;
        state.midwifeNames = getMidwfeNames(accounts);
      }
      else {
        state.midwives = [];
        state.midwifeNames = {};
      }
    });
    builder.addCase(getAllMidwives.rejected, (state, action) => {
      state.isLoadingMidwives = false;

      if (!action.meta.aborted && !action.meta.arg.signal?.aborted) {
        Toast.error({ title: 'Failed to retrieve midwife data' });
      }
    });

    builder.addCase(getAllSites.fulfilled, (state, action) => {
      if (action.payload) {
        const sites = action.payload.data
        state.sites = sites;
        state.siteNames = getSiteNames(sites);
      }
      else {
        state.sites = [];
        state.siteNames = {};
      }
    });
    builder.addCase(getAllSites.rejected, (state, action) => {
      if (!action.meta.aborted && !action.meta.arg.signal?.aborted) {
        Toast.error({ title: 'Failed to retrieve site data' });
      }
    });

    builder.addCase(createSite.fulfilled, (state, action) => {
      if (action.payload) {
        state.sites.push(action.payload.data);
        state.siteNames = getSiteNames(state.sites);
        Toast.success({ title: `Successfully created new site: '${action.payload.data.name}'` });
      }
    });
    builder.addCase(createSite.rejected, (state, action) => {
      if (!action.meta.aborted && !action.meta.arg.signal?.aborted) {
        Toast.error({ title: 'Unable to create new site' });
      }
    });
  },
});

export default accountSlice.reducer;
export const {
  logout,
  updateTokens,
  setSelectedMidwife,
} = accountSlice.actions;

export const login = createAsyncThunk('account/login',
  async ({ email, password, signal }: LoginData & EndpointRequest, { rejectWithValue }) => (
    AccountAPI.login({ email, password, signal })   // API success -> thunk payload is ApiResponse
    .catch(error => rejectWithValue(error))         // API failure -> thunk payload is ApiErrorResponse
));

export const registerNewAccount = createAsyncThunk('account/registerNewAccount',
  async (values: RegistrationData & EndpointRequest, { rejectWithValue }) => (
    AccountAPI.register(values)
    .catch(error => rejectWithValue(error))
));

export const forgotPassword = createAsyncThunk('account/forgotPassword',
  async ({ email, signal }: { email: string } & EndpointRequest, { rejectWithValue }) => (
    AccountAPI.forgotPassword({ email, signal })
    .catch(error => rejectWithValue(error))
))

export const changePassword = createAsyncThunk('account/changePassword',
  async ({ currentPassword, newPassword, signal }: { currentPassword: string, newPassword: string } & EndpointRequest, { rejectWithValue }) => (
    AccountAPI.changePassword({ currentPassword, newPassword, signal })
    .catch(error => rejectWithValue(error))
));

export const getAccountData = createAsyncThunk('account/getAccountData',
  async ({ signal }: EndpointRequest, { getState, rejectWithValue }) => {
    const email = selectEmail(getState() as RootState);

    return AccountAPI.getAccountData({ email, signal })
    .catch(error => rejectWithValue(error))
});

export const updateAccountData = createAsyncThunk('account/updateAccountData',
  async ({ firstName, lastName, signal }: ChangeAccountData & EndpointRequest, { getState, rejectWithValue }) => {
    const email = selectEmail(getState() as RootState);

    return AccountAPI.updateAccountData({ email, firstName, lastName, signal })
    .catch(error => rejectWithValue(error))
  },
);

export const getAllMidwives = createAsyncThunk('account/getAllMidwives',
  async ({ signal }: EndpointRequest, { rejectWithValue }) => (
    AccountAPI.getAllMidwives({ signal })
    .catch(error => rejectWithValue(error))
));

export const getAllSites = createAsyncThunk('account/getAllSites',
  async ({ signal }: EndpointRequest, { getState, rejectWithValue }) => {
    const role = selectRole(getState() as RootState);

    if (role === AccountRole.ADMIN || role === AccountRole.POWER_MEDIC) {
      return AccountAPI.getAllSites({ signal })
      .catch(error => rejectWithValue(error))
    }
  },
);

export const createSite = createAsyncThunk('account/createSite',
  async ({ name, secret, signal }: { name: string, secret: string } & EndpointRequest, { rejectWithValue }) => (
    AccountAPI.createSite({ name, secret, signal })
    .catch(error => rejectWithValue(error))
));

export const selectIsLoggingIn = (state: RootState) => state.account.isLoggingIn;
export const selectIsLoggedIn = (state: RootState) => !!state.account.refreshToken;
export const selectAccessToken = (state: RootState) => state.account.accessToken;
export const selectRefreshToken = (state: RootState) => state.account.refreshToken;

export const selectEmail = (state: RootState) => state.account.email;
export const selectFirstName = (state: RootState) => state.account.firstName;
export const selectLastName = (state: RootState) => state.account.lastName;
export const selectRole = (state: RootState) => state.account.role;
export const selectSiteId = (state: RootState) => state.account.site;

export const selectIsLoadingMidwives = (state: RootState) => state.account.isLoadingMidwives;
export const selectMidwives = (state: RootState) => state.account.midwives;
export const selectMidwifeNames = (state: RootState) => state.account.midwifeNames;
export const selectSelectedMidwife = (state: RootState) => state.account.selectedMidwife;

export const selectSites = (state: RootState) => state.account.sites;
export const selectSiteNames = (state: RootState) => state.account.siteNames;

export const selectJWT = createSelector([ selectAccessToken, selectRefreshToken ],
  (accessToken, refreshToken) => ({ accessToken, refreshToken }));

export const selectFullName = createSelector([ selectFirstName, selectLastName ],
  (firstName, lastName) => getFullName(firstName, lastName));

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { Indexable } from "../../types/misc";
import { CrfDataApi } from "../../types/crf";
import { ApiErrorResponse, ApiResponse, EndpointRequest } from "../../api/types/base";
import CrfAPI from "../../api/endpoints/crf";
import { AsyncThunkConfig } from "../types/redux";
import { ResponseCode } from "../../api/types/responseCode";
import { showErrorMessage } from "../utils";
import PatientAPI from "../../api/endpoints/patient";
import { Patient } from "../../types/patient";
import { removePatientIdPrefix } from "../../utils/testkit";

interface CrfState {
  isLoadingCrf: boolean,
  isUploadingCrf: boolean,
  data?: CrfDataApi,

  isLoadingCrfIds: boolean,
  crfIds?: string[],
}

const initialState: CrfState = {
  isLoadingCrf: false,
  isUploadingCrf: false,
  data: undefined,

  isLoadingCrfIds: false,
  crfIds: undefined,
}

// NOTE: 'state' is only mutable inside 'createSlice'
export const crfSlice = createSlice({
  name: 'crf',
  initialState,
  reducers: {
    clearCrfIds: (state) => {
      state.crfIds = undefined;
    },
    storeCrfData: (state, action: PayloadAction<any>) => {

    },
    updateCrf: (state, action: PayloadAction<{ name: string, value: any }>) => {
      const path = action.payload.name.split('.');
      let stateObj = state.data as Indexable;
      let nextObj;
      let i = 0;

      while (i < path.length - 1) {
        nextObj = stateObj[path[i]];

        // Path not found -> add it
        if (!nextObj) {
          stateObj[path[i]] = {};
          nextObj = stateObj[path[i]];
        }

        stateObj = nextObj;
        i++;
      }

      // if (stateObj[path[i]] !== action.payload.value) {
        stateObj[path[i]] = action.payload.value;
      // }
    },
    clearCrfData: (state) => {
      state.data = initialState.data;
    }
  },
  extraReducers: (builder) => {
    // Thunk: getCrfIds
    builder.addCase(getCrfIds.pending, (state, action) => { state.isLoadingCrfIds = true; });
    builder.addCase(getCrfIds.fulfilled, (state, action) => {
      state.isLoadingCrfIds = false;
      
      const patients = action.payload?.data || [];
      state.crfIds = patients.map(kit => removePatientIdPrefix(kit.id));
    });
    builder.addCase(getCrfIds.rejected, (state, action) => {
      state.isLoadingCrfIds = false;
      
      showErrorMessage(action.payload as ApiErrorResponse, { title: 'Failed to retrieve testkit data' });
    });

    // Thunk: getCrfData
    builder.addCase(getCrfData.pending, (state, action) => { state.isLoadingCrf = true; });
    builder.addCase(getCrfData.fulfilled, (state, action) => {
      state.isLoadingCrf = false;
      state.data = action.payload.data;
    });
    builder.addCase(getCrfData.rejected, (state, action) => {
      state.isLoadingCrf = false;

      const error = action.payload as ApiErrorResponse;
      const isBlankCrf = error.type === 'errorResponse' && error.status === 404 && error.code?.id === ResponseCode.EntityNotFound;

      // ID is valid but has no CRF data yet -> create blank CRF data
      if (isBlankCrf) {
        state.data = { id: action.meta.arg.id }
      }
      else {
        showErrorMessage(error, !isBlankCrf ? { title: 'Failed to retrieve CRF data' } : undefined);
      }
    });

    builder.addCase(uploadCrfData.pending, (state, action) => { state.isUploadingCrf = true; });
    builder.addCase(uploadCrfData.fulfilled, (state, action) => {
      state.isUploadingCrf = false;
      state.data = action.meta.arg.data;
    });
    builder.addCase(uploadCrfData.rejected, (state, action) => {
      state.isUploadingCrf = false;
      showErrorMessage(action.payload as ApiErrorResponse, { title: 'Failed to update CRF data' });
    });
  }
});

export default crfSlice.reducer;
export const {
  clearCrfIds,
  storeCrfData,
  updateCrf,
  clearCrfData,
} = crfSlice.actions;

export const getCrfIds = createAsyncThunk<ApiResponse<Patient[]>, EndpointRequest, AsyncThunkConfig>(
  'testkit/getCrfIds',
  async ({ signal }, { rejectWithValue }) => (
    // TestkitAPI.getAllKits({ signal, status: TestkitAPIStatus.ISSUED, descending: false })
    PatientAPI.getAllPatients({ signal })
    .catch(error => rejectWithValue(error))
));

export const getCrfData = createAsyncThunk<ApiResponse<CrfDataApi>, { id: string } & EndpointRequest, AsyncThunkConfig>('crf/getCrfData',
async ({ id, signal }, { rejectWithValue }) => (
  await CrfAPI.getCrf({ id, signal })         // API success -> thunk payload is ApiResponse
  .catch(error => rejectWithValue(error))     // API failure -> thunk payload is ApiErrorResponse
))

export const uploadCrfData = createAsyncThunk<ApiResponse, { data: CrfDataApi } & EndpointRequest, AsyncThunkConfig>('crf/uploadCrfData',
async ({ data, signal }, { rejectWithValue }) => (
  await CrfAPI.uploadCrf({ id: data.id.replace('CRF-', ''), data, signal })   // API success -> thunk payload is ApiResponse
  .catch(error => rejectWithValue(error))                 // API failure -> thunk payload is ApiErrorResponse
))

export const selectIsLoadingCrfIds = (state: RootState) => state.crf.isLoadingCrfIds;
export const selectIsUploadingCrf = (state: RootState) => state.crf.isUploadingCrf;
export const selectCrfIds = (state: RootState) => state.crf.crfIds;
export const selectCrf = (state: RootState) => state.crf.data;

import React from "react";
import { DateTime } from "luxon";
import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useDispatch, useSelector } from "../../redux/hooks";
import { selectRole } from "../../redux/slices/account";
import { getAllImages, getPatients, selectPatientImages, selectPatients } from "../../redux/slices/patient";
import { Screen, setScreenTitle } from "../../routes/screens";
import { AccountRole } from "../../types/account";
import { getImageStatusUILabel, IMAGE_STATUSES, ImageMetadata, ImageMetadataUI, ImageStatus, ImageType } from "../../types/image";
import { Testkit } from "../../types/testkit";
import { getISO } from "../../utils/dateTime";
import { convertImageMetadataToUI } from "../../components/Calendar/utils";
import ScreenBox from "../../components/ScreenBox";
import Section from "../../components/Section";
import SearchFilter from "../../components/SearchFilter";
import DialogImage from "../../components/dialogs/DialogImage";
import ImagePreview from "./ImagePreview";

const SEARCH_LIMIT = 25;

type FilterValues = {
  user: Testkit | null,
  status: ImageStatus | null,
  startDate: DateTime | null,
  endDate: DateTime | null,
}

const PhotosScreen = () => {
  const dispatch = useDispatch();
  const role = useSelector(selectRole);
  const users = useSelector(selectPatients);
  const images = useSelector(selectPatientImages);

  const [imageType, setImageType] = React.useState<ImageType>('raw');
  const [image, setImage] = React.useState<ImageMetadataUI | undefined>(undefined);
  const [openImage, setOpenImage] = React.useState(false);

  React.useEffect(() => {
    setScreenTitle(Screen.PHOTOS);
  }, []);
  
  // Fetch Image data
  React.useEffect(() => {
    const controller = new AbortController();
    // When this screen loads -> fetch all users & image data
    dispatch(getPatients({ signal: controller.signal }));
    dispatch(getAllImages({ limit: SEARCH_LIMIT, signal: controller.signal }));
    return () => { controller.abort() }
  }, [dispatch]);

  React.useEffect(() => {
    if (!openImage) {
      setImage(undefined);
    }
  }, [openImage]);

  const handleChangeImageType = (e: React.MouseEvent<HTMLElement>, newType: any) => {
    if (!!newType) {
      setImageType(newType);
    }
  }

  const handleSearch = (values: FilterValues) => {
    console.log(values);
    dispatch(getAllImages({
      id: values.user?.id || undefined,
      status: values.status || undefined,
      startTime: getISO(values.startDate),                              // From the start of this day
      endTime: getISO(values.endDate?.plus({ days: 1, seconds: -1 })),  // To the end of this day
      limit: SEARCH_LIMIT,
    }));
  }

  const handleClickImage = (image: ImageMetadata) => {
    setImage(convertImageMetadataToUI(image));
    setOpenImage(true);
  }
  
  return (
    <ScreenBox sx={{ flexDirection: 'column', justifyContent: 'start', alignItems: 'center', p: 0 }}>
      <Section sx={{ display: 'flex', flexDirection: 'row', gap: 2, mt: 1, mb: 2, mx: 1, px: 2, py: 2, width: '100%', maxWidth: 1300 }}>
        <SearchFilter
          initialValues={{ user: null, status: null, startDate: null, endDate: null } as FilterValues}
          filters={[
            { type: 'options', id: 'user', label: 'Study ID', options: users, getLabel: (kit) => kit.id, disabled: (values) => !!values.status },
            { type: 'options', id: 'status', label: 'Status', options: IMAGE_STATUSES, width: 250, disabled: (values) => !!values.user,
              getLabel: (status: ImageStatus) => getImageStatusUILabel(status),
            },
            { type: 'date', id: 'startDate', label: 'From' },
            { type: 'date', id: 'endDate', label: 'To' },
          ]}
          onSearch={handleSearch}
          sx={{ mr: 'auto' }}
        />

        {role === AccountRole.ADMIN && (
          <ToggleButtonGroup
            exclusive
            color='secondary'
            value={imageType}
            onChange={handleChangeImageType}
          >
            <ToggleButton value='raw'>Raw Image</ToggleButton>
            <ToggleButton value='whiteBalance'>White Balanced</ToggleButton>
            <ToggleButton value='mask'>Mask</ToggleButton>
          </ToggleButtonGroup>
        )}
      </Section>
      
      <Box sx={{
        display: 'flex', flexDirection: 'row',
        flexFlow: 'wrap', gap: 2, rowGap: 2, overflowY: 'auto',
        boxSizing: 'border-box', width: '100%',
        justifyContent: 'center',
        p: 2, pt: 0,
      }}>
        {images?.map((image) => (
          <ImagePreview key={image.id} image={image} imageType={imageType} onClick={handleClickImage}/>
        ))}
      </Box>

      <DialogImage image={image} open={openImage} setOpen={setOpenImage}/>
    </ScreenBox>
  );
}

export default PhotosScreen;
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "../../redux/hooks";
import { selectRole } from "../../redux/slices/account";
import { clearCrfData, clearCrfIds, getCrfData, getCrfIds, selectCrfIds } from "../../redux/slices/crf";
import { getPatientByID, selectSelectedPatient, setSelectedPatient } from "../../redux/slices/patient";
import { Screen, setScreenTitle } from "../../routes/screens";
import { CrfSection } from "./inputs";
import ScreenBox from "../../components/ScreenBox";
import DataSection from "./DataSection";
import NavSection from "./NavSection";

const CrfScreen = () => {
  const dispatch = useDispatch();
  const role = useSelector(selectRole);
  const testkitIds = useSelector(selectCrfIds);
  const patientData = useSelector(selectSelectedPatient);

  const navigate = useNavigate();
  const { id } = useParams();   // Fetch 'id' param from URL

  const [openSection, setOpenSection] = React.useState<CrfSection | undefined>(CrfSection.Demographics);
  const [selectedId, setSelectedId] = React.useState<string | null>(null);

  React.useEffect(() => {
    return () => {
      dispatch(setSelectedPatient(undefined));
      dispatch(clearCrfIds());
      dispatch(clearCrfData());
    }
  }, [dispatch]);

  React.useEffect(() => {
    const controller = new AbortController();
    
    setScreenTitle(Screen.CRF);
    dispatch(getCrfIds({ signal: controller.signal }));

    return () => { controller.abort() }
  }, [dispatch, role]);

  React.useEffect(() => {
    if (!!testkitIds && !!id) {
      // Update URL on page load (and once list of IDs have loaded)
      if (testkitIds.indexOf(id) >= 0) {
        // Valid ID -> set State to match
        setSelectedId(id);
      }
      else {
        // Invalid ID -> clear it from URL
        navigate('/crf');
      }
    }
  }, [navigate, id, testkitIds]);

  React.useEffect(() => {
    if (!!selectedId) {
      dispatch(getPatientByID({ id: selectedId }));
      dispatch(getCrfData({ id: selectedId }));
    }
    else {
      dispatch(setSelectedPatient(undefined));
      dispatch(clearCrfData());
    }
  }, [dispatch, selectedId]);

  const onClickSection = (id: CrfSection) => {
    setOpenSection(openSection === id ? undefined : id)
  }

  const onChangeSelectedId = (value: string | null) => {
    setSelectedId(value);
    navigate(!!value ? `/crf/${value}` : '/crf');
  }

  return (
    <ScreenBox>
      <NavSection
        openSection={openSection}
        patient={patientData}
        onClickSection={onClickSection}
      />
      <DataSection
        openSection={openSection}
        testkitIds={testkitIds || []}
        patient={patientData}
        onClickSection={onClickSection}
        onChangeSelectedId={onChangeSelectedId}
      />
    </ScreenBox>
  );
}

export default CrfScreen;
import AuthCall from "./auth";
import { FilterPagination, ApiResponse, EndpointRequest } from "../types/base";
import { FinishTrialReason, Patient, PatientFilter, PatientSorter } from "../../types/patient";
import { Testkit } from "../../types/testkit";

const PatientAPI = {
  getPatientByID: async ({ id, signal }: { id: string } & EndpointRequest) => await AuthCall.get({
    endpoint: `/secure/medical/patient/${id}`,
    signal,
  }) as ApiResponse<Patient>,

  getAllPatients: async ({ signal, ...params }: PatientFilter & PatientSorter & FilterPagination & EndpointRequest) => await AuthCall.get({
    endpoint: '/secure/medical/patient/instances',
    params,
    signal,
  }) as ApiResponse<Patient[]>,

  downloadAllPatients: async ({ filename, signal, ...params }: { filename?: string } & PatientFilter & PatientSorter & FilterPagination & EndpointRequest) => await AuthCall.download({
    method: 'get',
    endpoint: '/secure/medical/patient/instances',
    filename,
    params: { ...params, format: 'csv' },
    signal,
  }),

  issueKitToPatient: async ({ id, dueDate, signal }: { id: string, dueDate: string } & EndpointRequest) => await AuthCall.post({
    endpoint: `/secure/testkit/issue/${id}`,
    data: { dueDate },
    signal,
  }) as ApiResponse<Testkit>,

  issueKitToExistingPatient: async ({ primaryId, secondaryId, signal }: { primaryId: string, secondaryId: string } & EndpointRequest) => await AuthCall.post({
    endpoint: `/secure/testkit/issue/${secondaryId}`,
    data: { id: primaryId },
    signal,
  }) as ApiResponse<Testkit>,

  transferPatientToMidwife: async ({ id, oldMidwife, newMidwife, newSiteId, signal }: { id: string, oldMidwife: string, newMidwife: string, newSiteId?: string } & EndpointRequest) => await AuthCall.post({
    endpoint: `/secure/medical/patient/P-${id}/midwife/${oldMidwife}/swap`,
    data: { username: newMidwife, site: newSiteId },
    signal,
  }) as ApiResponse<Patient>,

  transferAllPatientsToMidwife: async ({ oldMidwife, newMidwife, newSiteId, signal }: { oldMidwife: string, newMidwife: string, newSiteId?: string } & EndpointRequest) => await AuthCall.post({
    endpoint: `/secure/medical/patient/all/midwife/${oldMidwife}/swap`,
    data: { username: newMidwife, site: newSiteId },
    signal,
  }) as ApiResponse,

  completePregnancy: async ({ id, reason, signal }: { id: string, reason: FinishTrialReason } & EndpointRequest) => await AuthCall.post({
    endpoint: `/secure/medical/patient/P-${id}/complete`,
    params: { reason },
    signal,
  }) as ApiResponse<Patient>,
};

export default PatientAPI;

import { MenuItem, TextField } from "@mui/material";
import { removeUndefined, validateNotEmpty } from "../../utils/global";
import { useDispatch } from "../../redux/hooks";
import { completePregnancy } from "../../redux/slices/patient";
import { DialogCloseHandler } from "../../types/dialog";
import BaseFormDialog from "../../components/dialogs/BaseFormDialog";
import { FinishTrialReason, getFinishReasonUILabel } from "../../types/patient";

type FinishTrialData = {
  reason: FinishTrialReason | null,
}

const DialogFinishTrial = ({
  open,
  userId,
  onClose,
}: {
  open: boolean,
  userId?: string,
  onClose?: DialogCloseHandler,
}) => {
  const dispatch = useDispatch();

  const handleValidateAddSecondaryID = ({ reason }: FinishTrialData) => removeUndefined({
    reason: validateNotEmpty(reason || undefined),
  })

  const handleSubmitAddSecondaryID = ({ reason }: FinishTrialData) => {
    if (!reason || !userId) return;

    dispatch(completePregnancy({ id: userId, reason }))
    .then(() => { onClose?.({}, 'closeClick') })
    .catch(() => {})
  }

  return (
    <BaseFormDialog
      title={`Inform the system that user ${userId} will no longer participate in the Salurate study.`}
      open={open}
      onClose={onClose}
      initialValues={{ reason: null } as FinishTrialData}
      validate={handleValidateAddSecondaryID}
      onSubmit={handleSubmitAddSecondaryID}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
      }) => (
        <>
          <TextField
            required
            select
            name='reason'
            label='Reason'
            value={values.reason || ''}
            error={!!errors.reason}
            helperText={errors.reason}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            {Object.values(FinishTrialReason).map((key) => (
              <MenuItem key={key} value={key}>{getFinishReasonUILabel(key)}</MenuItem>
            ))}
          </TextField>
        </>
      )}
    </BaseFormDialog>
  );
}

export default DialogFinishTrial;
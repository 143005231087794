export enum AccountRole {
  ADMIN = 'ADMIN',
  POWER_MEDIC = 'POWER_MEDIC',
  MEDIC = 'MEDIC',
}

export type AccountData = {
  objectType: 'account',
  username: string,
  firstName: string,
  lastName: string,
  activationCode: string,
  role: AccountRole,
  site?: string,
  status: number,
  failedLoginCount: number,
}

export type ChangeAccountData = {
  firstName?: string,
  lastName?: string,
}

export type SiteData = {
  id: string,
  name: string,
  secret: string,
}

export type ChangePasswordData = {
  currentPassword: string,
  newPassword: string,
}
export enum ResponseStatusType {
  ActionCompleted,
  Redirect,

  // RefreshedAccess,
  BadRequest,
  Unauthorized,
  PaymentRequired,
  Forbidden,
  NotFound,
  MethodNotAllowed,
  NotAcceptable,
  ClientError,

  Error,
  // Exception,
};

export const getResponseStatusTypeByStatus = (code: number) => {
  if (code < 300) {
    return ResponseStatusType.ActionCompleted;
  }
  else if (code < 400) {
    return ResponseStatusType.Redirect;
  }
  else if (code < 500) {
    switch (code) {
      case (400):
        return ResponseStatusType.BadRequest;
      case (401):
        return ResponseStatusType.Unauthorized;
      case (402):
        return ResponseStatusType.PaymentRequired;
      case (403):
        return ResponseStatusType.Forbidden;
      case (404):
        return ResponseStatusType.NotFound;
      case (405):
        return ResponseStatusType.MethodNotAllowed;
      case (406):
        return ResponseStatusType.NotAcceptable;
      default:
        return ResponseStatusType.ClientError;
    }
  }
  else {
    return ResponseStatusType.Error;
  }
};
import React from "react";
import { Box, CircularProgress, Typography, useTheme } from "@mui/material";

type Orientation = 'horizontal' | 'vertical';

const DEFAULT_LOADING_WIDTH  = 200;
const DEFAULT_LOADING_HEIGHT = 160;

const Image = ({
  src,
  alt,
  maxWidth = 0,
  maxHeight = 0,
  fadeDuration = 500,
  forceOrientation,
  onLoad,
  onError,
}: {
  src: string,
  alt: string,
  maxWidth?: number,
  maxHeight?: number,
  fadeDuration?: number,
  forceOrientation?: Orientation,
  onLoad?: React.ReactEventHandler<HTMLImageElement>,
  onError?: React.ReactEventHandler<HTMLImageElement>,
}) => {
  const theme = useTheme();

  const LOADING_WIDTH  = maxWidth  < DEFAULT_LOADING_WIDTH  ? maxWidth  : DEFAULT_LOADING_WIDTH;
  const LOADING_HEIGHT = maxHeight < DEFAULT_LOADING_HEIGHT ? maxHeight : DEFAULT_LOADING_HEIGHT;

  const [loaded, setLoaded] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [rawSize, setRawSize] = React.useState<{ width: number, height: number }>({ width: 0, height: 0 });
  const [rawOrientation, setRawOrientation] = React.useState<Orientation | undefined>(undefined);
  const [scaledSize, setScaledSize] = React.useState<{ width: number, height: number }>({ width: LOADING_WIDTH, height: LOADING_HEIGHT });
  const [rotatedSize, setRotatedSize] = React.useState<{ width: number, height: number }>({ width: LOADING_WIDTH, height: LOADING_HEIGHT });

  React.useEffect(() => {
    setError(false);
    setLoaded(false);
  }, [src]);

  React.useEffect(() => {
    if (loaded) {
      const rawOrientation = rawSize.width > rawSize.height ? 'horizontal' : 'vertical';
      setRawOrientation(rawOrientation);

      const switchOrientation = !!forceOrientation && rawOrientation !== forceOrientation;

      let adjustedWidth  = switchOrientation ? rawSize.height : rawSize.width;
      let adjustedHeight = switchOrientation ? rawSize.width  : rawSize.height;

      if (maxWidth > 0 || maxHeight > 0) {
        const xScale = maxWidth  ? adjustedWidth  / maxWidth  : 0;
        const yScale = maxHeight ? adjustedHeight / maxHeight : 0;
        const scale = Math.max(xScale, yScale);

        // console.log(`xScale: ${xScale.toFixed(3)}, yScale: ${yScale.toFixed(3)}, max: ${scale.toFixed(3)}`);
        
        adjustedWidth  /= scale;
        adjustedHeight /= scale;
      }

      setRotatedSize({ width: adjustedWidth, height: adjustedHeight });
      setScaledSize({
        width:  switchOrientation ? adjustedHeight : adjustedWidth,
        height: switchOrientation ? adjustedWidth  : adjustedHeight,
      });

      // console.log(`Raw size: ${rawSize.width}x${rawSize.height}, orientation: ${rawOrientation}, scaledSize: ${adjustedWidth.toFixed(3)}x${adjustedHeight.toFixed(3)}`);
    }
  }, [loaded, rawSize, maxWidth, maxHeight]);

  const handleLoad: React.ReactEventHandler<HTMLImageElement> = (e) => {
    // if (alt.indexOf('5') > -1) {
    //   return;
    // }

    setLoaded(true);

    const img = e.nativeEvent.target as HTMLImageElement;
    setRawSize({ width: img.naturalWidth, height: img.naturalHeight });

    onLoad?.(e);
  }

  const handleError: React.ReactEventHandler<HTMLImageElement> = (e) => {
    setError(true);

    onError?.(e);
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: rotatedSize.width, height: rotatedSize.height }}>
      <img
        src={src}
        alt={alt}
        width={loaded ? scaledSize.width : 0}
        height={loaded ? scaledSize.height : 0}
        onLoad={handleLoad}
        onError={handleError}
        style={{
          opacity: loaded ? 1 : 0,
          transform: (!!forceOrientation && forceOrientation !== rawOrientation) ? 'rotate(90deg)' : undefined,
          filter: loaded ? 'brightness(100%) saturate(100%)' : 'brightness(20%) saturate(20%)',
          ...(fadeDuration && {
            transition: theme.transitions.create([ 'opacity', 'filter' ], { duration: fadeDuration }),
          })
        }}
      />
      {!loaded && (
        <>
          {!error ? (
            <CircularProgress/>
          ) : (
            <Typography>{alt}</Typography>
          )}
        </>
      )}
    </Box>
  )
}

export default Image;
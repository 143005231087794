import React from "react";

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
}

export const useWindowDimensions = () => {
  const [windowDims, setWindowDims] = React.useState(getWindowDimensions());

  React.useEffect(() => {
    const handleResize = () => {
      setWindowDims(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDims;
}
import { Autocomplete, MenuItem, TextField } from "@mui/material";
import NumberInput from "../../components/NumberInput";
import BaseFormDialog from "../../components/dialogs/BaseFormDialog";
import { useDispatch, useSelector } from "../../redux/hooks";
import { selectRole, selectSites } from "../../redux/slices/account";
import { getStockTestkits, requestStock } from "../../redux/slices/testkit";
import { AccountRole, SiteData } from "../../types/account";
import { DialogCloseHandler } from "../../types/dialog";
import { REFRESH_DELAY, removeUndefined, validateInt, validateNotEmpty } from "../../utils/global";

type RequestData = {
  site: SiteData | null,
  count: string,
}

const COUNT_OPTIONS = [16, 32, 48, 64, 80, 96];

const DialogRequestKits = ({
  open,
  onClose,
}: {
  open: boolean,
  onClose?: DialogCloseHandler,
}) => {
  const dispatch = useDispatch();
  const role = useSelector(selectRole);
  const sites = useSelector(selectSites);

  const handleValidate = ({ site, count }: RequestData) => removeUndefined({
    site: role !== AccountRole.MEDIC ? validateNotEmpty(site?.id) : undefined,
    count: validateInt(count, 1, 100),
  })

  const handleSubmit = ({ site, count }: RequestData) => {
    const numOfKits = Number.parseInt(count);

    dispatch(requestStock({ siteId: site?.id, count: numOfKits }))
    .then(() => {
      onClose && onClose({}, 'closeClick');
        
      setTimeout(() => {
        // Repeat search with previous config after delay
        dispatch(getStockTestkits({}));
      }, REFRESH_DELAY);
    })
    .catch(() => {})
  }

  return (
    <BaseFormDialog
      title='Request additional Salurate kits to be sent to your registered address'
      open={open}
      onClose={onClose}
      initialValues={{ site: null, count: '' } as RequestData}
      validate={handleValidate}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        setFieldValue,
        handleChange,
        handleBlur,
      }) => (
        <>
          {role !== AccountRole.MEDIC && (
            <Autocomplete
              disablePortal
              options={sites}
              getOptionLabel={site => site.name}
              isOptionEqualToValue={(option: any, value) => value.id === option.id}
              value={values.site}
              onChange={(e, val) => { setFieldValue('site', val) }}
              onBlur={handleBlur}
              sx={{ mb: 2 }}
              renderInput={params => <TextField
                {...params}
                required
                name='site'
                label='Site'
                error={!!errors.site}
                helperText={errors.site}
              />}
            />
          )}

          {role === AccountRole.ADMIN ? (
            <NumberInput
              required
              format={/^\d{0,2}$/}
              name='count'
              label='Number of Kits'
              value={values.count}
              error={!!errors.count}
              errorText={errors.count}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          ) : (
            <TextField
              select
              name='count'
              label='Number of Kits'
              value={values.count}
              error={!!errors.count}
              helperText={errors.count}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              {/* <MenuItem value=''>-</MenuItem> */}
              {COUNT_OPTIONS.map((option) => (
                <MenuItem key={option} value={option}>{option}</MenuItem>
              ))}
            </TextField>
          )}
        </>
      )}
    </BaseFormDialog>
  );
}

export default DialogRequestKits;
import ApiCall from './base';
import { ApiRequest, ApiResponse, EndpointRequest } from '../types/base';
import { JwtToken } from '../types/auth';
import { ResponseCode } from '../types/responseCode';
import { StoreType } from '../../redux/store';
import { logout, selectAccessToken, selectRefreshToken, updateTokens } from '../../redux/slices/account';

let store: StoreType;
export const injectStore = (appStore: StoreType) => { store = appStore }

const AuthCall = {
  buildOptions: (options = {}) => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: selectAccessToken(store.getState()),
    };
    return { ...headers, ...options };
  },

  call: async (method: 'get' | 'post' | 'put' | 'delete', {
    endpoint,
    params = undefined,
    data = undefined,
    options = {},
    signal,
  }: ApiRequest) => {
    const callEndpoint = (allowAnyStatus: boolean) => ApiCall.axiosInstance({
      method,
      url: endpoint,
      params,
      data,
      headers: AuthCall.buildOptions(options),
      signal,
      validateStatus: allowAnyStatus ? () => true : ApiCall.validateStatus,
    });
    
    const response = await callEndpoint(true)
    .then(async (axiosResponse) => {
      const processedResponse = ApiCall.processResponse(axiosResponse);

      // Status 200-299 -> API request succeeded
      if (ApiCall.validateStatus(processedResponse.status)) {
        return processedResponse;
      }
      // Status 401 & code API-40105 -> JWT token expired -> refresh JWT token then re-try API request
      else if (processedResponse.code?.id === ResponseCode.JwtTokenExpiredException) {
        return await AuthCall.refreshAccess({})
        .then((refreshResponse) => {
          store.dispatch(updateTokens(refreshResponse.data));
        })
        .then(() => callEndpoint(false))
        .then(ApiCall.processResponse)
        // If refresh fails, initial API request fails & user logs out
        .catch((error) => {
          store.dispatch(logout());
          // TODO: toast -> user timed out
          return Promise.reject(error);
        })
      }
      // Anything else -> continue with rejected Promise
      else {
        return Promise.reject(ApiCall.processResponseError(axiosResponse));
      }
    })
    .catch(ApiCall.processError)
    return response;
  },
  get: async ({
    endpoint,
    params = undefined,
    options = {},
    signal,
  }: ApiRequest) => AuthCall.call('get', { endpoint, params, options, signal }),
  post: async ({
    endpoint,
    params = undefined,
    data = undefined,
    options = {},
    signal,
  }: ApiRequest) => AuthCall.call('post', { endpoint, params, data, options, signal }),
  put: async ({
    endpoint,
    params = undefined,
    data = undefined,
    options = {},
    signal,
  }: ApiRequest) => AuthCall.call('put', { endpoint, params, data, options, signal }),
  delete: async ({
    endpoint,
    params = undefined,
    options = {},
    signal,
  }: ApiRequest) => AuthCall.call('delete', { endpoint, params, options, signal }),

  download: async ({
    method,
    filename,
    endpoint,
    params = undefined,
    data = undefined,
    options = {},
    signal,
  }: {
    method: 'get' | 'post',
    filename?: string,
  } & ApiRequest) => ApiCall.download({
    method,
    filename,
    endpoint,
    params,
    data,
    options: AuthCall.buildOptions(options),
    signal,
  }),
  
  refreshAccess: async ({ signal }: EndpointRequest) => await ApiCall.get({
    endpoint: '/open/account/jwt/refresh',
    options: {
      'Content-Type': 'application/json',
      Authorization: selectRefreshToken(store.getState()),
    },
    signal,
  }) as ApiResponse<JwtToken>,
};

export default AuthCall;

import { Checkbox, FormControlLabel, MenuItem, TextField } from "@mui/material";
import { Download as DownloadIcon } from "@mui/icons-material";
import { useSelector } from "../../redux/hooks";
import { selectStockTestkitFilter } from "../../redux/slices/testkit";
import { DialogCloseHandler } from "../../types/dialog";
import { ADMIN_STATUSES, TestkitUIFilter, convertFilterToAPI, getCompletedFilterOverride, getDispatchedFilterOverride, getKitStatusUILabel } from "../../types/testkit";
import TestkitAPI from "../../api/endpoints/testkit";
import BaseFormDialog from "../../components/dialogs/BaseFormDialog";
import { selectRole } from "../../redux/slices/account";

const DialogDownload = ({
  open,
  onClose,
}: {
  open: boolean,
  onClose?: DialogCloseHandler,
}) => {
  const role = useSelector(selectRole);
  const reduxFilter = useSelector(selectStockTestkitFilter);

  const handleDownload = (filter: TestkitUIFilter) => {
    TestkitAPI.downloadAllKits({
      filename: 'testkits.csv',
      ...convertFilterToAPI(filter, role),
    })
    .then(() => { onClose?.({}, 'closeClick') })
    .catch(error => { console.error(error) });
  }

  return (
    <BaseFormDialog
      title='Download CSV data for all kits matching the filter options'
      open={open}
      onClose={onClose}
      confirmText='Download'
      confirmIcon={<DownloadIcon/>}
      initialValues={reduxFilter}
      onSubmit={handleDownload}
    >
      {({
        values,
        handleChange,
      }) => {
        const dispatchedOverride  = getDispatchedFilterOverride(values.status);
        const finishedOverride    = getCompletedFilterOverride(values.status);

        return (
        <>
          <TextField
            name='idPattern'
            label='Study ID'
            value={values.idPattern}
            onChange={handleChange}
          />

          <TextField
            select
            name='status'
            label='Status'
            value={values.status}
            onChange={handleChange}
            sx={{ my: 1 }}
          >
            <MenuItem value=''>-</MenuItem>
            {ADMIN_STATUSES.map((status) => (
              <MenuItem key={status} value={status}>{getKitStatusUILabel(status)}</MenuItem>
            ))}
          </TextField>

          <FormControlLabel
            disabled={dispatchedOverride !== undefined}
            label='Include dispatched'
            name='includeDispatched'
            control={<Checkbox
              name='dispatched'
              checked={(dispatchedOverride === undefined) ? values.dispatched : dispatchedOverride}
              onChange={handleChange}
            />}
          />
          <FormControlLabel
            disabled={finishedOverride !== undefined}
            label='Include finished'
            name='includeFinished'
            control={<Checkbox
              name='finished'
              checked={(finishedOverride === undefined) ? values.includeCompleted : finishedOverride}
              onChange={handleChange}
            />}
          />
        </>
      )}}
    </BaseFormDialog>
  );
}

export default DialogDownload;
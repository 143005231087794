import React from "react";
import { Screen, setScreenTitle } from "../../routes/screens";
import ScreenBox from "../../components/ScreenBox";
import DetailsSection from "./DetailsSection";
import TableSection from "./TableSection";

const UsersScreen = () => {
  React.useEffect(() => {
    setScreenTitle(Screen.USERS);
  }, []);
  
  return (
    <ScreenBox>
      <TableSection sx={{ mr: 1 }}/>
      <DetailsSection/>
    </ScreenBox>
  );
}

export default UsersScreen;
import { DateTime } from "luxon";
import { BLANK } from "./global";

export const DUE_DATE_WEEK       = 40;
export const WEEK_DISPLAY_CUTOFF = 40;

/** JS days are Sun=0 to Sat=6. Luxon days are Mon=0 to Sun=6 */
export const convertDayJsToLuxon = (jsDay: number) => (jsDay + 6) % 7;
/** Luxon days are Mon=0 to Sun=6. JS days are Sun=0 to Sat=6 */
export const convertDayLuxonToJs = (luxDay: number) => (luxDay + 1) % 7;

export const getISO = (dt?: DateTime | null) => dt?.toISO() || undefined;

export const dateToString = (dt: DateTime) => dt.toLocal().toLocaleString(DateTime.DATE_SHORT);
export const timeToString = (dt: DateTime, includeSeconds: boolean = false) => dt.toLocal().toLocaleString(includeSeconds ? DateTime.TIME_WITH_SECONDS : DateTime.TIME_SIMPLE);
export const dateTimeToString = (dt: DateTime, includeSeconds: boolean = false) => dt.toLocal().toLocaleString(includeSeconds ? DateTime.DATETIME_SHORT_WITH_SECONDS : DateTime.DATETIME_SHORT);

export const isoDateTimeToDate = (isoDateTime: string) => dateToString(DateTime.fromISO(isoDateTime, {zone: 'utc'}).toLocal());
export const isoDateTimeToTime = (isoDateTime: string) => timeToString(DateTime.fromISO(isoDateTime, {zone: 'utc'}).toLocal());
export const isoDateTimeToDateTime = (isoDateTime: string) => dateTimeToString(DateTime.fromISO(isoDateTime, {zone: 'utc'}).toLocal());

export const calcPregnancyWeek = (dueDate: DateTime) => {
  return Math.max(0, Math.floor(DUE_DATE_WEEK - dueDate.diffNow('weeks').weeks));
}

export const pregnancyWeekToString = (week: number, onlyNumber: boolean = true) => {
  if (week <= 0) {
    return BLANK;
  }
  
  const value = week > WEEK_DISPLAY_CUTOFF ? `${WEEK_DISPLAY_CUTOFF}+` : week.toString();

  return onlyNumber ? value : `Week ${value}`;
}

export const getDiffDays = (timestamp: string) => {
  const submitTime  = DateTime.fromISO(timestamp, {zone: 'utc'}).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const diff        = Math.floor(Math.abs(submitTime.diffNow('days').days));

  return diff;
}

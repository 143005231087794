import React from "react";
import { FormControl, FormHelperText, InputAdornment, InputLabel, OutlinedInput, SxProps } from "@mui/material";

const REGEX_INTEGER_ABS = /^\d+?$/;
const REGEX_DECIMAL_ABS = /^\d*\.?\d*$/;

export type TextInputProps = {
  required?: boolean,
  disabled?: boolean,
  decimal?: boolean,
  format?: RegExp,
  name?: string,
  label: React.ReactNode,
  textAdornment?: string,
  textAdornmentPosition?: 'start' | 'end',
  helperText?: string,
  /** If true, it defaults to the value of *name*. Provide a string to override this */
  autoComplete?: string | true,
  value?: string,
  error?: boolean,
  errorText?: string,
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>,
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>,
  sx?: SxProps,
}

const NumberInput = ({
  required,
  disabled,
  decimal,
  format,
  name,
  label,
  textAdornment,
  textAdornmentPosition = 'end',
  helperText,
  autoComplete,
  value,
  error,
  errorText,
  onChange,
  onBlur,
  sx,
}: TextInputProps) => {
  const onChangeValue: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
    // TODO: filter out disallowed characters
    let newValue = e.target.value;
    // newValue = String(newValue?.trim());

    // New value must match regex
    if (newValue.match(format || (decimal ? REGEX_DECIMAL_ABS : REGEX_INTEGER_ABS)) || newValue === '') {
      onChange?.(e);
    }
  }

  let adornment;

  if (!!textAdornment) {
    adornment = <InputAdornment disableTypography position={textAdornmentPosition} sx={theme => ({
      color: disabled ? theme.palette.text.disabled : theme.palette.text.secondary,
    })}>{textAdornment}</InputAdornment>
  }

  return (
    <FormControl variant='outlined' required={required} disabled={disabled} sx={sx}>
      {!!label && (
        <InputLabel size='small' required={required} disabled={disabled} error={error}>{label}</InputLabel>
      )}
      <OutlinedInput
        disabled={disabled}
        size='small'
        type='text'
        label={label}
        name={name}
        autoComplete={autoComplete === true ? name : autoComplete}
        value={value}
        error={error}
        onChange={onChangeValue}
        onBlur={onBlur}
        startAdornment={textAdornmentPosition === 'start' && adornment}
        endAdornment={textAdornmentPosition === 'end' && adornment}
      />
      {error && errorText && (
        <FormHelperText disabled={disabled} error>{errorText}</FormHelperText>
      )}
      {helperText && (
        <FormHelperText disabled={disabled}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
}

export default NumberInput;
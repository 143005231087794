import React from "react";
import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, SxProps } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export type TextInputProps = {
  required?: boolean,
  disabled?: boolean,
  multiline?: boolean,
  rows?: number,
  name?: string,
  label: React.ReactNode,
  textAdornment?: string,
  textAdornmentPosition?: 'start' | 'end',
  helperText?: string,
  type?: React.HTMLInputTypeAttribute,
  /** If true, it defaults to the value of *name*. Provide a string to override this */
  autoComplete?: string | true,
  value?: string,
  error?: boolean,
  errorText?: string,
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>,
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>,
  sx?: SxProps,
}

const TextInput = ({
  required,
  disabled,
  multiline,
  rows = 4,
  name,
  label,
  textAdornment,
  textAdornmentPosition = 'end',
  helperText,
  type = 'text',
  autoComplete,
  value,
  error,
  errorText,
  onChange,
  onBlur,
  sx,
}: TextInputProps) => {
  // TODO: remove debug
  if (name === 'email') {
    console.log({
      type, autoComplete, label, value,
    })
  }

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickToggleShow = () => {
    setShowPassword(show => !show);
  }

  const handleMouseDownToggleShow = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  }

  let adornment;

  if (type === 'password') {
    adornment = (
      <InputAdornment position='end'>
        <IconButton edge='end' onClick={handleClickToggleShow} onMouseDown={handleMouseDownToggleShow}>
          {showPassword ? <VisibilityOff/> : <Visibility/>}
        </IconButton>
      </InputAdornment>
    )
  }
  else if (!!textAdornment) {
    adornment = <InputAdornment disableTypography position={textAdornmentPosition} sx={theme => ({
      color: disabled ? theme.palette.text.disabled : theme.palette.text.secondary,
    })}>{textAdornment}</InputAdornment>
  }

  return (
    <FormControl variant='outlined' required={required} disabled={disabled} sx={sx}>
      {!!label && (
        <InputLabel size='small' required={required} disabled={disabled} error={error} shrink={!!value || undefined}>{label}</InputLabel>
      )}
      <OutlinedInput
        disabled={disabled}
        multiline={multiline}
        rows={rows}
        size='small'
        type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
        label={label}
        notched={!!value || undefined}
        name={name}
        autoComplete={autoComplete === true ? name : autoComplete}
        value={value || ''}
        error={error}
        onChange={onChange}
        onBlur={onBlur}
        startAdornment={textAdornmentPosition === 'start' && adornment}
        endAdornment={(type === 'password' || textAdornmentPosition === 'end') && adornment}
      />
      {error && errorText && (
        <FormHelperText disabled={disabled} error>{errorText}</FormHelperText>
      )}
      {helperText && (
        <FormHelperText disabled={disabled}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
}

export default TextInput;
import React from 'react';
import {
  Groups as UsersIcon,
  InsertPhoto as PhotosIcon,
  WarehouseRounded as StockIcon,
  Group as MidwivesIcon,
  Assignment as CrfIcon,
  Help as FaqIcon,
  AccountCircle as AccountIcon,
} from '@mui/icons-material';

export const setScreenTitle = (screen?: Screen) => {
  const desc = screen !== undefined ? `${Screens[screen].title} - Salurate` : 'Salurate Clinician Portal';
  document.title = process.env.REACT_APP_ENVIRONMENT === 'production' ? desc : `${desc} [DEV]`
}

export enum Screen {
  LOGIN,
  USERS,
  PHOTOS,
  STOCK,
  MIDWIVES,
  STATS,
  CRF,
  FAQ,
  ACCOUNT,
}

export type ScreenData = {
  id: Screen,
  title: string,
  href: string,
  icon?: React.ReactNode,
}

const iconSize = 'large'
// const iconProps = { fontSize: 'large' }

export const Screens = {
  [Screen.LOGIN]: {
    id: Screen.LOGIN,
    title: 'Login',
    href: '/login',
    // icon: '',
  } as ScreenData,

  [Screen.USERS]: {
    id: Screen.USERS,
    title: 'Users',
    href: '/users',
    icon: <UsersIcon fontSize={iconSize}/>,
  } as ScreenData,

  [Screen.PHOTOS]: {
    id: Screen.PHOTOS,
    title: 'Photos',
    href: '/photos',
    icon: <PhotosIcon fontSize={iconSize}/>,
  } as ScreenData,
  
  [Screen.STOCK]: {
    id: Screen.STOCK,
    title: 'Stock',
    href: '/stock',
    icon: <StockIcon fontSize={iconSize}/>,
  } as ScreenData,

  [Screen.MIDWIVES]: {
    id: Screen.MIDWIVES,
    title: 'Midwives',
    href: '/midwives',
    icon: <MidwivesIcon fontSize={iconSize}/>,
  } as ScreenData,

  [Screen.STATS]: {
    id: Screen.STATS,
    title: 'Statistics',
    href: '/stats',
    // icon: '',
  } as ScreenData,

  [Screen.CRF]: {
    id: Screen.CRF,
    title: 'CRF',
    href: '/crf',
    icon: <CrfIcon fontSize={iconSize}/>,
  } as ScreenData,

  [Screen.FAQ]: {
    id: Screen.FAQ,
    title: 'FAQ',
    href: '/faq',
    icon: <FaqIcon fontSize={iconSize}/>,
  } as ScreenData,

  [Screen.ACCOUNT]: {
    id: Screen.ACCOUNT,
    title: 'Account',
    href: '/account',
    icon: <AccountIcon fontSize={iconSize}/>,
  } as ScreenData,
}

export const UNAUTH_SCREENS = [
  Screens[Screen.LOGIN],
];

export const ADMIN_SCREENS = [
  Screens[Screen.USERS],
  Screens[Screen.PHOTOS],
  Screens[Screen.STOCK],
  Screens[Screen.MIDWIVES],
  // Screens[Screen.STATS],
  Screens[Screen.FAQ],
  Screens[Screen.ACCOUNT],
];

export const POWER_MEDIC_SCREENS = [
  Screens[Screen.USERS],
  // Screens[Screen.PHOTOS],
  Screens[Screen.STOCK],
  Screens[Screen.MIDWIVES],
  Screens[Screen.CRF],
  Screens[Screen.FAQ],
  Screens[Screen.ACCOUNT],
];

export const MEDIC_SCREENS = [
  Screens[Screen.USERS],
  // Screens[Screen.PHOTOS],
  Screens[Screen.STOCK],
  // Screens[Screen.MIDWIVES],
  Screens[Screen.CRF],
  Screens[Screen.FAQ],
  Screens[Screen.ACCOUNT],
];
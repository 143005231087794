import {
  // TypedUseSelectorHook,
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from "react-redux";
import { AppDispatch, RootState } from "./store";

// Use these instead of the default redux hooks so they work with TypeScript
// export const useDispatch: () => AppDispatch = useReduxDispatch;
// export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export const useDispatch = useReduxDispatch.withTypes<AppDispatch>();
export const useSelector = useReduxSelector.withTypes<RootState>();

import React from "react";
import { Box } from "@mui/material";
import { Screen, setScreenTitle } from "../../routes/screens";
import ScreenBox from "../../components/ScreenBox";
import DetailsSection from "./DetailsSection";
import ControlSection from "./ControlSection";
import TableSection from "./TableSection";

const StockScreen = () => {
  React.useEffect(() => {
    setScreenTitle(Screen.STOCK);
  }, []);
  
  return (
    <ScreenBox>
      <TableSection/>

      <Box sx={{ display: 'flex', flexDirection: 'column', alignSelf: 'stretch', ml: 1 }}>
        <DetailsSection sx={{ mb: 1, flexGrow: 1 }}/>
        <ControlSection/>
      </Box>
    </ScreenBox>
  );
}

export default StockScreen;
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import accountReducer from "./slices/account";
import testkitReducer from "./slices/testkit";
import patientReducer from "./slices/patient";
import crfReducer from "./slices/crf";

const combinedReducer = combineReducers({
  account: accountReducer,
  testkit: testkitReducer,
  patient: patientReducer,
  crf: crfReducer,
})

const rootReducer = (state: any, action: any) => {
  if (action.type === 'account/logout') {
    state = undefined;
  }

  return combinedReducer(state, action);
}

export const store = configureStore({
  reducer: rootReducer,
})

export type StoreType = typeof store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

import React from "react";
import { Box } from "@mui/material";
import { CrfSection, getInput } from "../inputs";
import { CrfData } from "../../../types/crf";
import { ChangeEventHandlerBoolean, ChangeEventHandlerDate, ChangeEventHandlerRadio, ChangeEventHandlerText } from "../types";
import Expander from "../Expander";
import { REGEX_INT_3 } from "../../../utils/crf";

const NeonatalSection = React.forwardRef(({
  crf,
  editing,
  onChangeBoolean,
  onChangeText,
  onChangeDate,
  open,
  onClick,
}: {
  crf: CrfData,
  editing: boolean,
  onChangeBoolean: ChangeEventHandlerBoolean,
  onChangeBooleanInverted: ChangeEventHandlerBoolean,
  onChangeText: ChangeEventHandlerText,
  onChangeRadio: ChangeEventHandlerRadio,
  onChangeDate: ChangeEventHandlerDate,
  open: boolean,
  onClick: (id: CrfSection) => void,
}, ref) => {
  return (
    <Expander
      ref={ref}
      id={CrfSection.Neonatal}
      open={open}
      onClick={onClick}
    >
      {getInput({ type: 'checkbox', name: 'neonatal_sga', label: 'Small for Gestational Age', value: crf.neonatal_sga, disabled: !editing, onChange: onChangeBoolean })}
      {getInput({ type: 'checkbox', name: 'neonatal_rds', label: 'Respiratory Distress Syndrome', value: crf.neonatal_rds, disabled: !editing, onChange: onChangeBoolean })}
      {getInput({ type: 'checkbox', name: 'neonatal_bpd', label: 'Bronchopulmonary Displasia', value: crf.neonatal_bpd, disabled: !editing, onChange: onChangeBoolean })}
      {getInput({ type: 'checkbox', name: 'neonatal_ivh', label: 'Intraventricular Haemorrhage, grade III or IV', value: crf.neonatal_ivh, disabled: !editing, onChange: onChangeBoolean })}
      {getInput({ type: 'checkbox', name: 'neonatal_rop', label: 'Retinopathy of Prematurity', value: crf.neonatal_rop, disabled: !editing, onChange: onChangeBoolean })}
      {getInput({ type: 'checkbox', name: 'neonatal_nec', label: 'Necrotising Enterocolitis', value: crf.neonatal_nec, disabled: !editing, onChange: onChangeBoolean })}
      {getInput({ type: 'checkbox', name: 'neonatal_prematureBirth', label: 'Premature Birth, gestational week < 37+0', value: crf.neonatal_prematureBirth, disabled: !editing, onChange: onChangeBoolean })}

      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {getInput({ type: 'checkbox', name: 'neonatal_nicuAdmission', label: 'NICU admission', value: crf.neonatal_nicuAdmission, disabled: !editing, onChange: onChangeBoolean, sx: { flexGrow: 1 }})}
        {getInput({ type: 'date', name: 'neonatal_nicuAdmissionDate', label: 'Date of admission', value: crf.neonatal_nicuAdmissionDate, disabled: !editing || !crf.neonatal_nicuAdmission, onChange: onChangeDate })}
      </Box>

      {getInput({ type: 'checkbox', name: 'neonatal_cpap', label: 'CPAP', value: crf.neonatal_cpap, disabled: !editing, onChange: onChangeBoolean })}
      
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {getInput({ type: 'checkbox', name: 'neonatal_intubated', label: 'Intubated', value: crf.neonatal_intubated, disabled: !editing, onChange: onChangeBoolean, sx: { flexGrow: 1 }})}
        {getInput({ type: 'number', width: 150, name: 'neonatal_daysOnVentilation', format: REGEX_INT_3, suffix: 'days', value: crf.neonatal_daysOnVentilation, disabled: !editing || !crf.neonatal_intubated, onChange: onChangeText })}
      </Box>

      {getInput({ type: 'checkbox', name: 'neonatal_other', label: 'Other perinatal complications', value: crf.neonatal_other, disabled: !editing, onChange: onChangeBoolean })}
      {getInput({ type: 'text', name: 'neonatal_comments', label: 'Comments', value: crf.neonatal_comments, disabled: !editing, onChange: onChangeText, sx: { mt: 2 }})}
    </Expander>
  );
})

export default NeonatalSection;
import React from "react";
import { DateTime } from "luxon";
import { ImageMetadata } from "../../types/image";
import { Box, CircularProgress, SxProps, Typography } from "@mui/material";
import { TOTAL_WIDTH, getDayOfMonth, groupImageMetadata } from "./utils";
import { GroupedImagesCalendar } from "./types";
import CalendarMonth from "./CalendarMonth";

type MonthData = {
  year: number,
  month: number,
}

const Calendar = ({
  loading,
  startDate,
  dueDate,
  finishDate,
  images,
  sx,
}: {
  loading?: boolean,
  startDate?: DateTime,
  dueDate?: DateTime,
  finishDate?: DateTime,
  images?: ImageMetadata[],
  sx?: SxProps,
}) => {
  const [now, setNow] = React.useState<DateTime | undefined>(undefined);
  const [months, setMonths] = React.useState<MonthData[]>([]);
  const [groupedImages, setGroupedImages] = React.useState<GroupedImagesCalendar>({});

  // Group images by month & day
  React.useEffect(() => { setGroupedImages(!!images ? groupImageMetadata(images) : {}) }, [images]);

  React.useEffect(() => {
    if (!startDate || !dueDate) {
      return;
    }

    // Set to last day of last month
    let lastMonth = finishDate ? DateTime.max(dueDate, finishDate) : dueDate;
    lastMonth = lastMonth.plus({ months: 1 }).set({ day: -1, hour: 23, minute: 59 });
    let month = startDate.set({ day: 1 });
    const monthsArr = [];

    while (month <= lastMonth) {
      monthsArr.push({ year: month.year, month: month.month })
      month = month.plus({ months: 1 });
    }

    setNow(DateTime.now());
    setMonths(monthsArr);
  }, [startDate, dueDate, finishDate]);

  React.useEffect(() => {
    if (loading) {
      return;
    }

    const now = DateTime.now();
    const date = (!!dueDate && now > dueDate) ? dueDate : now;
    const id = `${date.year}-${date.month}`;

    document.getElementById(id)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [groupedImages, dueDate, loading]);

  return (
    <Box sx={{ width: TOTAL_WIDTH, display: 'flex', flexDirection: 'column', flex: '1 1 auto', overflow: 'hidden auto', pr: 4, ...sx }}>
      {(!startDate || !dueDate) ? (
        <Typography sx={theme => ({ mt: 1, color: theme.palette.text.secondary })}>Select a user to view their submissions</Typography>
      ) : (
        <>
          {!!images && (
            <Box sx={theme => ({
              opacity: loading ? 0.4 : 1,
              transition: theme.transitions.create([ 'opacity' ])
            })}>
              {(months.map((data, index) => (
                <CalendarMonth
                  key={`${data.year}_${data.month}`}
                  hideDivider={index === months.length - 1}
                  year={data.year}
                  month={data.month}
                  today={(!!now && data.month === now.month) ? now.day : undefined}
                  startDay={getDayOfMonth(data.month, data.year, startDate)}
                  finishDay={finishDate ? getDayOfMonth(data.month, data.year, finishDate) : 50}
                  dueDay={getDayOfMonth(data.month, data.year, dueDate)}
                  formattedImages={groupedImages?.[data.year]?.[data.month]}
                />
              )))}
            </Box>
          )}

          {loading && (
            <Box sx={{ display: 'flex', position: 'absolute', top: '50%', width: 'inherit', justifyContent: 'center' }}>
              <CircularProgress size={60}/>
            </Box>
          )}
        </>
      )}
    </Box>
  );
}

export default Calendar;
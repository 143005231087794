import { Box, Button, Divider, Typography } from "@mui/material";
import { useDispatch } from "../../redux/hooks";
import { setDisplayedImage } from "../../redux/slices/patient";
import { ImageMetadataUI, getImageStatusUILabel } from "../../types/image";
import { timeToString } from "../../utils/dateTime";
import { convertImageMetadataFromUI } from "./utils";

const DayTooltip = ({
  today,
  startDay = false,
  finishDay = false,
  dueDay = false,
  imageData,
}: {
  today: boolean,
  startDay?: boolean,
  finishDay?: boolean,
  dueDay?: boolean,
  imageData?: ImageMetadataUI,
}) => {
  const dispatch = useDispatch();

  const handleClickViewImage = () => {
    if (imageData) {
      dispatch(setDisplayedImage(convertImageMetadataFromUI(imageData)));
    }
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {today && (<Typography>Today</Typography>)}
      {startDay && (<Typography>Kit Issued</Typography>)}
      {finishDay && (<Typography>Finished Trial</Typography>)}
      {dueDay && (<Typography>Due Date (Ultrasound)</Typography>)}

      {imageData && (
        <>
          {(today || startDay || finishDay || dueDay) && (<Divider sx={theme => ({ my: 1, borderColor: theme.palette.grey[400] })}/>)}

          <Typography>{timeToString(imageData.imageTimestamp)}</Typography>
          <Typography>{getImageStatusUILabel(imageData.status)}</Typography>

          <Divider sx={theme => ({ mt: 1, mb: 0.5, borderColor: theme.palette.grey[400] })}/>
          <Button variant='text' onClick={handleClickViewImage} sx={theme => ({ color: theme.palette.primary.contrastText, alignSelf: 'center' })}>View Image</Button>
        </>
      )}
    </Box>
  );
}

export default DayTooltip;
import ApiCall from "./base";
import AuthCall from "./auth";
import { ApiResponse, EndpointRequest } from "../types/base";
import { JwtToken } from "../types/auth";
import { LoginData, RegistrationData } from "../../types/auth";
import { AccountData, ChangeAccountData, ChangePasswordData, SiteData } from "../../types/account";

const AccountAPI = {
  login: async ({ email, password, signal }: LoginData & EndpointRequest) => await ApiCall.post({
    endpoint: '/secure/jwt/login',
    options: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${btoa(`${email}:${password}`)}`,
    },
    signal,
  }) as ApiResponse<JwtToken>,

  /**
   * Register a new user.
   * NOTE: temp password policy requires min of 8 chars, 1+ uppercase, 1+ lowercase, 1+ number. Policy should be
   * reviewed and decided on at later date.
   */
  register: async ({ email, password, activationCode, firstName, lastName, signal }: RegistrationData & EndpointRequest) => await ApiCall.post({
    endpoint: '/open/account/instance',
    data: { username: email, password, activationCode, firstName, lastName },
    options: { 'Content-Type': 'application/json' },
    signal,
  }) as ApiResponse<unknown>,

  getAccountData: async ({ email, signal }: { email: string } & EndpointRequest) => await AuthCall.get({
    endpoint: `/secure/account/instance/${email}`,
    signal,
  }) as ApiResponse<AccountData>,

  getAllAdmins: async ({ signal }: EndpointRequest) => await AuthCall.get({
    endpoint: '/secure/account/instances/admin',
    signal,
  }) as ApiResponse<AccountData[]>,

  getAllMidwives: async ({ signal }: EndpointRequest) => await AuthCall.get({
    endpoint: '/secure/account/instances/medical',
    signal,
  }) as ApiResponse<AccountData[]>,

  getMidwivesBySite: async ({ siteId, signal }: { siteId: string } & EndpointRequest) => await AuthCall.get({
    endpoint: `/secure/account/bySite/${siteId}`,
    signal,
  }) as ApiResponse<AccountData[]>,

  forgotPassword: async ({ email, signal }: { email: string } & EndpointRequest) => await ApiCall.post({
    endpoint: '/open/account/password/forgot',
    params: { username: email },
    signal,
  }) as ApiResponse<undefined>,

  changePassword: async ({ currentPassword, newPassword, signal }: ChangePasswordData & EndpointRequest) => await AuthCall.post({
    endpoint: '/secure/account/password/change',
    data: { currentPassword, newPassword },
    signal,
  }) as ApiResponse<AccountData>,

  updateAccountData: async ({ email, firstName, lastName, signal }: { email: string } & ChangeAccountData & EndpointRequest) => await AuthCall.put({
    endpoint: `/secure/account/instance/${email}`,
    data: { firstName, lastName },
    signal,
  }) as ApiResponse<AccountData>,

  getAllSites: async ({ signal }: EndpointRequest) => await AuthCall.get({
    endpoint: '/secure/medical/site',
    signal,
  }) as ApiResponse<SiteData[]>,

  createSite: async ({ name, secret, signal }: { name: string, secret: string } & EndpointRequest) => await AuthCall.post({
    endpoint: '/secure/medical/site',
    data: { name, secret },
    signal,
  }) as ApiResponse<SiteData>,

  updateSiteData: async ({ id, name, secret, signal }: { id: string, name: string, secret: string } & EndpointRequest) => await AuthCall.put({
    endpoint: `/secure/medical/site/${id}`,
    data: { name, secret },
    signal,
  }) as ApiResponse<SiteData>,

  transferAccountToSite: async ({ email, siteId, signal }: { email: string, siteId: string } & EndpointRequest) => await AuthCall.post({
    endpoint: `/secure/account/${email}/site/swap`,
    data: { id: siteId },
    signal,
  }) as ApiResponse<AccountData>,
};

export default AccountAPI;

import AuthCall from "./auth";
import { ApiResponse, EndpointRequest, FilterPaginationDateTime } from "../types/base";
import { ImageMetadata, ImageStatus } from "../../types/image";
import { removeUndefined } from "../../utils/global";

const ImageAPI = {
  getImageByUUID: async ({ uuid, signal }: { uuid: string } & EndpointRequest) => await AuthCall.get({
    endpoint: `/secured/image-metadata/instances/${uuid}`,
    signal,
  }) as ApiResponse<ImageMetadata>,

  getAllImages: async ({ startTime, endTime, offset, limit, signal }: FilterPaginationDateTime & EndpointRequest) => await AuthCall.get({
    endpoint: '/secured/image-metadata/instances',
    params: { startTime, endTime, offset, limit },
    signal,
  }) as ApiResponse<ImageMetadata[]>,

  getImagesByPatient: async ({ id, startTime, endTime, offset, limit, signal }: { id: string } & FilterPaginationDateTime & EndpointRequest) => await AuthCall.get({
    endpoint: `/secured/image-metadata/byPatient/${id}`,
    params: { startTime, endTime, offset, limit },
    signal,
  }) as ApiResponse<ImageMetadata[]>,

  getImagesByStatus: async ({ status, startTime, endTime, offset, limit, signal }: { status: ImageStatus } & FilterPaginationDateTime & EndpointRequest) => await AuthCall.get({
    endpoint: `/secured/image-metadata/byStatus/${status}`,
    params: { startTime, endTime, offset, limit },
    signal,
  }) as ApiResponse<ImageMetadata[]>,

  reprocessImage: async ({ image, overrideKitId, overridePatientId, signal }: { image: ImageMetadata, overrideKitId?: string, overridePatientId?: string } & EndpointRequest) => await AuthCall.post({
    endpoint: `/secured/image-metadata/reprocess/${image.id}`,
    data: removeUndefined({
      // ...image,
      testkit: overrideKitId     ?? undefined,
      patient: overridePatientId ?? undefined,
    }),
    signal,
  }) as ApiResponse<ImageMetadata>,
};

export default ImageAPI;

import { TableSortConfig } from "../components/Table/types";
import { BLANK, removeUndefined } from "../utils/global";
import { AccountRole } from "./account";

export enum TestkitAPIStatus {
  NEW           = 'NEW',
  STOCK         = 'STOCK',
  ALLOCATED     = 'ALLOCATED',
  ISSUED        = 'ISSUED',
  DONE          = 'DONE',
}

export enum TestkitUIStatus {
  NEW           = 'NEW',
  STOCK         = 'STOCK',
  ALLOCATED     = 'ALLOCATED',
  DISPATCHED    = 'DISPATCHED',
  ISSUED        = 'ISSUED',
  DONE          = 'DONE',
}

export const ADMIN_STATUSES = [
  TestkitUIStatus.NEW,
  TestkitUIStatus.STOCK,
  TestkitUIStatus.ALLOCATED,
  TestkitUIStatus.DISPATCHED,
  TestkitUIStatus.ISSUED,
  TestkitUIStatus.DONE,
]

export const MIDWIFE_STATUSES = [
  TestkitUIStatus.ALLOCATED,
  TestkitUIStatus.DISPATCHED,
  TestkitUIStatus.ISSUED,
  TestkitUIStatus.DONE,
]

export const getTestkitStatusLabel = (kit?: Testkit) => {
  switch (kit?.status) {
    case TestkitAPIStatus.NEW:
      return 'New';
    case TestkitAPIStatus.STOCK:
      return 'Stock';
    case TestkitAPIStatus.ALLOCATED:
      return !!kit.dispatched ? 'Dispatched' : 'Allocated';
    case TestkitAPIStatus.ISSUED:
      return 'Issued';
    case TestkitAPIStatus.DONE:
      return 'Finished';
    default:
      return BLANK;
  }
}

export const getKitStatusUILabel = (status?: TestkitUIStatus) => {
  switch (status) {
    case TestkitUIStatus.NEW:
      return 'New';
    case TestkitUIStatus.STOCK:
      return 'Stock';
    case TestkitUIStatus.ALLOCATED:
      return 'Allocated';
    case TestkitUIStatus.DISPATCHED:
      return 'Dispatched';
    case TestkitUIStatus.ISSUED:
      return 'Issued';
    case TestkitUIStatus.DONE:
      return 'Finished';
    default:
      return BLANK;
  }
}

export const TestkitStatusPriority = {
  [TestkitAPIStatus.ALLOCATED]: 4,
  [TestkitAPIStatus.STOCK]: 3,
  [TestkitAPIStatus.NEW]: 2,
  [TestkitAPIStatus.ISSUED]: 1,
  [TestkitAPIStatus.DONE]: 0,
}

export type StatusTransition = {
  status: TestkitAPIStatus,
  timestamp: string,      // ISO DateTime
}

export type Testkit = {
  objectType: 'testkit',
  id: string,             // 123-456-789
  serial: number,
  patient?: string,       // P-123-456-7889
  midwife?: string,
  site?: string,

  status: TestkitAPIStatus,
  transitions: StatusTransition[],
  dispatched?: string,
  
  manufacturer?: string,
  paperLot?: string,
  paperExpiry?: string,   // Date
  swabLot?: string,
  swabExpiry?: string,    // Date
}

export type TestkitAPIFilter = {
  idPattern?: string,
  siteId?: string,
  status?: TestkitAPIStatus,
  midwife?: string,
  manufacturer?: string,
  dispatched?: boolean,
  includeCompleted?: boolean,
}

export type TestkitUIFilter = {
  idPattern: string,
  siteId?: string,
  status: TestkitUIStatus | '',
  midwife?: string,
  manufacturer?: string,
  dispatched: boolean,
  includeCompleted: boolean,
}

export const getDispatchedFilterOverride = (status: TestkitUIStatus | '') => {
  if (!!status) {
    switch (status) {
      case TestkitUIStatus.DISPATCHED:
      case TestkitUIStatus.ISSUED:
      case TestkitUIStatus.DONE:
        // Only show dispatched kits
        return true;
      default:
        // Do not show dispatched kits
        return false;
    }
  }
  else {
    // No override -> use user-defined filter
    return undefined;
  }
}

export const getCompletedFilterOverride = (status: TestkitUIStatus | '') => {
  if (!status) {
    return undefined;
  }
  else {
    return status === TestkitUIStatus.DONE;
  }
}

export const convertFilterToAPI = (filter: TestkitUIFilter, role?: AccountRole) => {
  // Dispatched: Undefined -> all kits, True -> only dispatched, False -> only not dispatched
  let dispatched: boolean | undefined;
  
  if (role === AccountRole.ADMIN) {
    // If filtering by status, override the 'include dispatched' filter
    if (!!filter.status) {
      switch (filter.status) {
        case TestkitUIStatus.DISPATCHED:
        case TestkitUIStatus.ISSUED:
        case TestkitUIStatus.DONE:
          dispatched = true;
          break;
        default:
          dispatched = false;
          break;
      }
    }
    // Otherwise, UI true/false -> API undefined/false
    else {
      dispatched = filter.dispatched ? undefined : false;
    }
  }
  else {
    // MEDIC/POWER_MEDIC -> all kits
    dispatched = undefined;
  }
  
  return removeUndefined({
    ...filter,
    idPattern: filter.idPattern || undefined,
    status: filter.status === TestkitUIStatus.DISPATCHED ? TestkitAPIStatus.ALLOCATED : (filter.status || undefined),
    dispatched,
    includeCompleted: filter.status === TestkitUIStatus.DONE || filter.includeCompleted || undefined,
  } as TestkitAPIFilter) as TestkitAPIFilter;
}

export type TestkitAPISorter = {
  statusSort?: boolean,
  descending?: boolean,
}

export type TestkitUISorter = TableSortConfig & {
  column: 'id' | 'status',
}

export const convertSorterToAPI = (sorter?: TestkitUISorter) => {
  const apiSorter: TestkitAPISorter = { descending: !!sorter && sorter.dir === 'desc' }

  if (sorter?.column === 'status') {
    apiSorter.statusSort = true;
  }

  return apiSorter;
}